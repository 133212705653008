export interface FeatureFlag {
  flipper_id: string;
  features: { [feature: string]: { enabled: boolean } };
}

type FeatureGateKey =
  | "boolean"
  | "actors"
  | "percentage_of_actors"
  | "percentage_of_time"
  | "groups";

interface FeatureGate {
  key: FeatureGateKey;
  name: string;
  value: string[];
}

export interface FeatureFlagResponse {
  features: {
    gates: FeatureGate[];
    key: FEATURE_FLAG_ID;
    state: FEATURE_STATE;
  }[];
}

export interface FeatureFlagActors {
  companyIds: number[];
  workspaceIds: number[];
  userIds: number[];
}

export type FeatureFlagActorMap = {
  [key in FEATURE_FLAG_ID]?: FeatureFlagActors | boolean;
};

export enum FEATURE_STATE {
  ON = "on",
  OFF = "off",
  CONDITIONAL = "conditional",
}

export enum FEATURE_FLAG_ID {
  COM_PUBLIC_API = "COM-public-api",
  COM_WEBSITE_MONITORING = "COM-website-monitoring",
  GENG_TRAINING_MODULE_REVAMP = "GENG-training-module-revamp",
  GENG_ATTESTATION_MODULE_REVAMP = "GENG_ATTESTATION_MODULE_REVAMP",
  GENG_CUSTOM_COLUMNS = "GENG-custom-columns",
  GENG_ACCOUNTS_ACTIVITY = "GENG-accounts-activity",
  GENG_ACCOUNTS_ATTACHMENT_VIEWER = "GENG-accounts-attachment-viewer",
  GENG_SEI_WEB_MONITORING = "GENG_SEI_WEB_MONITORING",
  GENG_QUESTIONNAIRES = "GENG_QUESTIONNAIRES",
  GENG_PAGINATION = "GENG-pagination",
  GENG_PDF_EXPORT_FOR_ACCOUNTS = "GENG_PDF_EXPORT_FOR_ACCOUNTS",
  GENG_HIDE_THEMIS_POLICIES_LIBRARY = "GENG_HIDE_THEMIS_POLICIES_LIBRARY",
}

export enum ACTOR_TYPE {
  USER = "User",
  WORKSPACE = "Workspace",
  COMPANY = "Company",
}
