import type { CardProps } from "@mui/material";
import { Container, Stack, Typography } from "@mui/material";
import type { ReactNode } from "react";

import { Card } from "../card";

export interface EmptyStateCardProps extends Omit<CardProps, "title"> {
  title?: ReactNode;
  description?: ReactNode;
}

export function EmptyStateCard({
  title,
  description,
  children,
  ...rest
}: EmptyStateCardProps) {
  return (
    <Card variant="contained" {...rest}>
      <Container
        maxWidth="xs"
        component={Stack}
        padding={2}
        alignItems="center"
      >
        {typeof title === "string" ? (
          <Typography variant="h5">{title}</Typography>
        ) : (
          title
        )}

        {typeof description === "string" ? (
          <Typography variant="body1" color="textSecondary" textAlign="center">
            {description}
          </Typography>
        ) : (
          description
        )}

        {children}
      </Container>
    </Card>
  );
}
