import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { Link, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import type { RecordVersion } from "@/api";
import { PolicyPdfExport } from "@/components/table/policy/policy-pdf-export";
import { useMainStore } from "@/contexts/Store";

import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import SlideMenu from "../../slideMenu/SlideMenu";
import MoveToSectionPopup from "../shared/MoveToSectionPopup";
import SendRecordVersion from "../shared/SendRecordVersion";
import ShareToCW from "../shared/ShareToCW";
import EmailAttest from "./EmailAttest";
import PolicyDeleteFlow from "./PolicyDeleteFlow";

interface Props {
  isPolicy: boolean;
  recordVersion: RecordVersion;
  showOnlyIcon: boolean;
  tableID: number;
  moduleWorkspaceID?: number;
  tableName?: string;
}

function PolicyContextMenu({
  tableName,
  recordVersion,
  moduleWorkspaceID,
  tableID,
  isPolicy,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const location = useLocation();

  // Variables
  const { canDeleteRecords } = mainStore.userPermissions;
  const { active_workspace: activeWorkspace } = mainStore.users.user;
  const { workspaceID } = mainStore.context;
  const { list: sections } = mainStore.sectionTags;
  const hasSections = sections.length > 0;
  const { hasModuleWriteAccess, canArchiveUnarchive } =
    mainStore.userPermissions;
  const { taskDetail } = mainStore;

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [view, setView] = useState("main"); // one of ['main', 'approved', 'delete', 'archive', 'move']
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const [sendSlide, setSendSlide] = useState(false);
  const currentStore = isPolicy ? mainStore.policies : mainStore.procedures;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordName = getRecordName(
    isPolicy ? "policy" : "procedures",
    moduleWorkspaces,
    true,
  );

  const modelName = useMemo(() => {
    if (tableName === "Finalized") {
      return "Policy";
    }

    if (tableName === "ProceduresFinalized") {
      return "Procedure";
    }

    return "Unknown Model Name";
  }, [tableName]);

  const onPopupOpen = () => {
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
    setView("main");
  };

  const onArchive = () => {
    setView("archive");
  };

  const onMove = () => {
    setView("move");
  };

  const onDelete = () => {
    setView("delete");
  };

  const handlePdfExport = () => {
    setView("pdf-export");
  };

  const openSlideMenu = () => {
    setShowSlideMenu(true);
    setShowPopup(false);
  };

  const openSendSlideMenu = () => {
    setShowSlideMenu(true);
    setShowPopup(false);
    setSendSlide(true);
  };

  const closeSlideMenu = () => {
    setShowSlideMenu(false);
    setSendSlide(false);
  };

  const handleArchive = () => {
    currentStore.archive(recordVersion.id);
    setShowPopup(false);
  };

  const handleCreateTask = () => {
    setShowPopup(false);
    taskDetail.openFromRecord(recordVersion);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'rvID' implicitly has an 'any' type.
  const handleMove = async (rvID, sectionTagID) => {
    await currentStore.updateSection(rvID, sectionTagID);
  };

  const trigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="points-button-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          showPopup,
      })}
    />
  );

  return (
    <>
      <Popup
        position="bottom right"
        trigger={trigger}
        open={showPopup}
        onOpen={onPopupOpen}
        onClose={onPopupClose}
        keepTooltipInside
      >
        {view === "main" && (
          <div className="table-dropdown policy-dropdown">
            <div>
              <ul>
                {[
                  `/workspaces/${workspaceID}/modules/policy/drafts`,
                  `/workspaces/${workspaceID}/modules/procedures/drafts`,
                ].includes(location.pathname) && (
                  <Link
                    to={`/workspaces/${workspaceID}/modules/${
                      isPolicy ? "policy" : "procedures"
                    }/${recordVersion.id}`}
                    data-testid="view-detail"
                  >
                    View {recordName} Detail
                  </Link>
                )}
                {[
                  `/workspaces/${workspaceID}/modules/policy/drafts`,
                  `/workspaces/${workspaceID}/modules/policy/finalized`,
                  `/workspaces/${workspaceID}/modules/procedures/drafts`,
                  `/workspaces/${workspaceID}/modules/procedures/finalized`,
                ].includes(location.pathname) && (
                  <Link
                    to={`/workspaces/${workspaceID}/modules/${
                      isPolicy ? "policy" : "procedures"
                    }/${recordVersion.id}/historical-versions`}
                  >
                    View Previous Versions
                  </Link>
                )}
                {[
                  `/workspaces/${workspaceID}/modules/policy/finalized`,
                  `/workspaces/${workspaceID}/modules/procedures/finalized`,
                ].includes(location.pathname) &&
                  hasModuleWriteAccess && (
                    <li onClick={openSlideMenu}>
                      Email / Attest {isPolicy ? "Policy" : "Procedure"}
                    </li>
                  )}
                {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
                {["Finalized"].includes(tableName) &&
                  activeWorkspace?.is_internal === true &&
                  hasModuleWriteAccess && (
                    <ShareToCW
                      recordName={recordName}
                      sendTrigger={openSendSlideMenu}
                    />
                  )}
                {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
                {["ProceduresFinalized"].includes(tableName) &&
                  activeWorkspace?.is_internal === true &&
                  hasModuleWriteAccess && (
                    <ShareToCW
                      recordName={recordName}
                      sendTrigger={openSendSlideMenu}
                    />
                  )}
                {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
                {["Finalized", "ProceduresFinalized"].includes(tableName) &&
                  hasModuleWriteAccess &&
                  canArchiveUnarchive && (
                    <>
                      {[
                        `/workspaces/${workspaceID}/modules/policy/finalized`,
                        `/workspaces/${workspaceID}/modules/procedures/finalized`,
                      ].includes(location.pathname) && <hr />}
                      <li
                        onClick={onArchive}
                        data-testid="archive-button-trigger"
                      >
                        Archive
                      </li>
                    </>
                  )}

                <li onClick={handleCreateTask}>Create Task</li>
                <li onClick={handlePdfExport}>Export PDF Report</li>

                {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
                {["Drafts"].includes(tableName) &&
                  !(activeWorkspace && !activeWorkspace.is_internal) &&
                  hasModuleWriteAccess && (
                    <>
                      <ShareToCW
                        recordName={recordName}
                        sendTrigger={openSendSlideMenu}
                      />
                      {hasSections && (
                        <li onClick={onMove} data-testid="move-button-trigger">
                          Move to
                        </li>
                      )}

                      {canDeleteRecords && (
                        <>
                          <hr />
                          <li
                            onClick={onDelete}
                            data-testid="policy-dropdown-delete"
                          >
                            Delete
                          </li>
                        </>
                      )}
                    </>
                  )}
                {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
                {["ProceduresDrafts"].includes(tableName) &&
                  !(activeWorkspace && !activeWorkspace.is_internal) &&
                  hasModuleWriteAccess && (
                    <>
                      <ShareToCW
                        recordName={recordName}
                        sendTrigger={openSendSlideMenu}
                      />
                      {hasSections && (
                        <li onClick={onMove} data-testid="move-button-trigger">
                          Move to
                        </li>
                      )}
                      {canDeleteRecords && (
                        <>
                          <hr />
                          <li
                            onClick={onDelete}
                            data-testid="procedure-dropdown-delete"
                          >
                            Delete
                          </li>
                        </>
                      )}
                    </>
                  )}
                {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
                {["Drafts", "ProceduresDrafts"].includes(tableName) &&
                  activeWorkspace &&
                  !activeWorkspace.is_internal &&
                  hasModuleWriteAccess &&
                  (hasSections || canDeleteRecords) && (
                    <>
                      {hasSections && (
                        <li onClick={onMove} data-testid="move-button-trigger">
                          Move to
                        </li>
                      )}
                      {canDeleteRecords && (
                        <>
                          <hr />
                          <li onClick={onDelete}>Delete</li>
                        </>
                      )}
                    </>
                  )}
              </ul>
            </div>
          </div>
        )}

        {view === "pdf-export" && (
          <PolicyPdfExport
            recordVersionId={recordVersion.id}
            onPopupClose={onPopupClose}
          />
        )}

        {view === "delete" && (
          <PolicyDeleteFlow
            onDeny={onPopupClose}
            recordVersionID={recordVersion.id}
            // @ts-expect-error TS(2322) FIXME: Type '{ onDeny: () => void; recordVersionID: numbe... Remove this comment to see the full error message
            tableID={tableID}
          />
        )}

        {view === "archive" && (
          <div
            className="table-dropdown success-dropdown archive"
            style={{ width: 230 }}
          >
            <div>
              <h4>Archive {modelName}</h4>
              <p>
                Please read carefully and make sure this {modelName} qualifies
                the following points before you confirm archive:
              </p>
              <ul className="ul-square">
                <li>{modelName} is no longer enforced</li>
                <li>No further updates can be made</li>
                <li>
                  {modelName} is Not shown in the governance library or
                  finalized {modelName} view
                </li>
                <li>Any open draft copy has been deleted</li>
              </ul>
              <div className="confirmation">
                <button onClick={handleArchive}>Yes</button>
                <button onClick={() => setShowPopup(false)}>No</button>
              </div>
            </div>
          </div>
        )}

        {view === "move" && (
          <MoveToSectionPopup
            moduleName={isPolicy ? "Policies" : "Procedures"}
            recordVersionID={recordVersion.id}
            onMove={handleMove}
            onClose={() => setShowPopup(false)}
          />
        )}
      </Popup>
      <SlideMenu open={showSlideMenu} closeSlideMenu={closeSlideMenu}>
        {!sendSlide &&
          [
            `/workspaces/${workspaceID}/modules/policy/finalized`,
            `/workspaces/${workspaceID}/modules/procedures/finalized`,
          ].includes(location.pathname) && (
            <EmailAttest
              moduleWorkspaceID={moduleWorkspaceID}
              recordVersionID={recordVersion.id}
            />
          )}

        {sendSlide &&
          [
            "Drafts",
            "ProceduresDrafts",
            "Finalized",
            "ProceduresFinalized",
            // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          ].includes(tableName) && (
            <SendRecordVersion
              recordVersionID={recordVersion.id}
              recordName={recordName}
            />
          )}
      </SlideMenu>
    </>
  );
}

export default observer(PolicyContextMenu);
