import "./global.scss";

import type { ReactNode } from "react";

import type { MuiProvidersProps } from "./mui-theme-provider/mui-theme-provider";
import { MuiThemeProvider } from "./mui-theme-provider/mui-theme-provider";
import { SnackbarProvider } from "./snackbar-provider/snackbar-provider";

export interface UiLibraryProviderProps {
  muiProvidersProps?: Partial<MuiProvidersProps>;
  children: ReactNode;
}

export const UiLibraryProvider = ({
  muiProvidersProps,
  children,
}: UiLibraryProviderProps) => {
  return (
    <MuiThemeProvider defaultMode="light" {...muiProvidersProps}>
      <SnackbarProvider>{children}</SnackbarProvider>
    </MuiThemeProvider>
  );
};
