import type { GetReferenceType } from "@themis/shared/components/fields/dynamic-value/field-configuration-types";

import type {
  FieldConfiguration,
  FieldConfigurationReferenceType,
  ThemisRecord,
} from "@/api";

type CustomFieldValue =
  ThemisRecord["custom_fields"][keyof ThemisRecord["custom_fields"]];

const getIdDefaultValue = (
  value: CustomFieldValue,
  config: FieldConfiguration,
) => {
  if (config.multiselect) {
    return value ?? [];
  }

  return value ?? undefined;
};

const getThemisDocumentDefaultValue = (
  value:
    | GetReferenceType<"ThemisDocument">
    | GetReferenceType<"ThemisDocument">[]
    | null,
) => {
  if (!value) {
    return null;
  }

  if (Array.isArray(value)) {
    // TODO: Implement array
    return [];
  }

  return {
    type: value.file?.content_type,
    signedId: value.file?.signed_id,
    name: value.file?.file_name,
    url: value.file?.file_url,
  };
};

type Getters = {
  [Type in FieldConfigurationReferenceType]:
    | ((value: GetReferenceType<Type>, config: FieldConfiguration) => unknown)
    | null;
};

const getters: Getters = {
  ThemisDocument: getThemisDocumentDefaultValue,
  Account: null,
  Attachment: null,
  RecordReference: null,
  ThemisRecord: null,
  Contact: null,
  Contract: null,
  Department: null,
  Issue: null,
  Policy: null,
  Questionnaire: null,
  RecordLink: null,
  RecordVersion: null,
  Review: null,
  Task: null,
  User: getIdDefaultValue,
  VendorChecklist: null,
  MonitoringAsset: null,
  MonitoringFinding: null,
};

export const getReferenceDefaultValue = (
  value: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  field: FieldConfiguration,
) => {
  if (!field.reference_type) {
    return null;
  }

  const getDefaultValue = getters[field.reference_type];

  const defaultValue = getDefaultValue?.(value, field);

  return defaultValue;
};
