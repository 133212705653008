import type { ComponentType, ReactElement } from "react";
import { useState } from "react";

import { styled } from "../../../../styles";
import { LinearProgress } from "../../../feedback/linear-progress/linear-progress";
import { Box } from "../../../layout/box/box";
import type { BoxProps } from "../../../layout/box/box";
import { getMediaType } from "../get-media-type/get-media-type";
import type { MediaFile } from "../types/media-file";
import type { MediaType } from "../types/media-type";
import { MediaError } from "./media-error/media-error";
import { PreviewRendererContainer } from "./preview-renderer-container/preview-renderer-container";
import type { BasePreviewRendererProps } from "./preview-renderers/base-preview-renderer-props";
import { ImagePreviewRenderer } from "./preview-renderers/image-preview-renderer/image-preview-renderer";
import { PdfPreviewRenderer } from "./preview-renderers/pdf-preview-renderer/pdf-preview-renderer";

export interface MediaViewerProps extends BoxProps {
  file: MediaFile;
  previewWidth?: number;
  zoomLevel?: number;
  showBorder?: boolean;
}

const renderers: Record<MediaType, ComponentType<BasePreviewRendererProps>> = {
  pdf: PdfPreviewRenderer,
  image: ImagePreviewRenderer,
};

const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  "&[data-show-border=true]": {
    border: `1px solid ${theme.palette.divider}`,
  },
}));

export function MediaViewer({
  file,
  previewWidth = 600,
  zoomLevel = 1,
  showBorder,
  ...rest
}: MediaViewerProps): ReactElement {
  const mediaType = getMediaType(file.content_type);
  const Renderer = mediaType ? renderers[mediaType] : undefined;

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(!!Renderer);

  const handleError = () => {
    setHasError(true);
    setIsLoading(false);
  };

  return (
    <StyledBox data-show-border={showBorder} {...rest}>
      <PreviewRendererContainer>
        {!Renderer || hasError ? (
          <MediaError file={file} />
        ) : (
          <Renderer
            file={file}
            previewWidth={previewWidth}
            zoomLevel={zoomLevel}
            onError={handleError}
            onLoaded={() => setIsLoading(false)}
          />
        )}
      </PreviewRendererContainer>

      {isLoading && (
        <Box position="absolute" top={0} width="100%">
          <LinearProgress color="primary" />
        </Box>
      )}
    </StyledBox>
  );
}
