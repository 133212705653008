import { useCallback, useMemo, useState } from "react";

import { computeGridState } from "./compute-grid-state";
import type { DataGridState } from "./data-grid-state";

export function useDataGridState(initialState: Partial<DataGridState> = {}) {
  const [state, setState] = useState<DataGridState>({
    filters: undefined,
    pageIndex: 0,
    pageSize: 10,
    sorts: undefined,
    ...initialState,
  });

  const updateState = useCallback((params: Partial<DataGridState>) => {
    setState((prevState: DataGridState) => {
      return computeGridState(prevState, params);
    });
  }, []);

  const queryParams = useMemo(() => {
    const offset =
      state.pageIndex != null && state.pageSize != null
        ? state.pageIndex * state.pageSize
        : undefined;
    return {
      filters: state.filters,
      limit: state.pageSize,
      offset,
      sorts: state.sorts,
    };
  }, [state]);

  return {
    dataGridState: state,
    updateDataGridState: updateState,
    dataGridQueryParams: queryParams,
  };
}
