import { createAuth0Client } from "@auth0/auth0-spa-js";
import { Checkbox } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import * as AuthAPI from "@/api/legacy/auth";
import { AUTHENTICATION_OPTION } from "@/api/legacy/auth/types";
import { Button, Flex } from "@/components/Elements";
import EmailInput from "@/components/form/EmailInput";
import PasswordInput from "@/components/form/PasswordInput";
import { useMainStore } from "@/contexts/Store";
import { formatErrors } from "@/utils/errors";

import Header from "../components/Header";
import LoginProgressSpinner from "../components/LoginProgressSpinner";

function SignIn() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [auth0Client, setAuth0Client] = useState(null);
  const [authenticationOptions, setAuthenticationOptions] = useState<
    AUTHENTICATION_OPTION[]
  >([]);
  const [authErrorText, setAuthErrorText] = useState("");
  const [organizationUID, setOrganizationUID] = useState<string | undefined>(
    undefined,
  );
  const [hasRedirectOnlySSOLogin, setHasRedirectOnlySSOLogin] = useState(false);
  const [hasButtonLoading, setHasButtonLoading] = useState(false);
  const [hasSSOButtonLoading, setHasSSOButtonLoading] = useState(false);

  // Variables
  const { webSessionErrors, banner } = mainStore.webSessions;
  const organizationUIDFromURL = new URL(window.location.href).searchParams.get(
    "organization",
  );
  const currentOrganizationUID = organizationUID ?? organizationUIDFromURL;
  const formattedErrors = formatErrors(webSessionErrors) || authErrorText;
  const hasEmailPasswordSSOTemplate =
    authenticationOptions.includes(AUTHENTICATION_OPTION.EMAIL_PASSWORD) &&
    authenticationOptions.includes(AUTHENTICATION_OPTION.SSO);
  const hasEmailPasswordTemplate =
    !hasEmailPasswordSSOTemplate &&
    authenticationOptions.includes(AUTHENTICATION_OPTION.EMAIL_PASSWORD);
  const hasEmailVerificationTemplate =
    !hasEmailPasswordSSOTemplate && !hasEmailPasswordTemplate;
  const shouldDisabledSubmitButton = hasEmailVerificationTemplate
    ? !emailValue.length
    : !emailValue.length && !passwordValue.length;

  // Hooks
  const history = useHistory();

  // Effects
  useEffect(() => {
    (async () => {
      if (!currentOrganizationUID) {
        return;
      }

      const data = await mainStore.auth0.fetchOrganization(
        currentOrganizationUID,
      );
      if (!data) {
        mainStore.toast.setErrorText("Auth0 organization not found");
        return;
      }

      const code = new URL(window.location.href).searchParams.get("code");
      const errorDescription = new URL(window.location.href).searchParams.get(
        "error_description",
      );

      const auth0 = await createAuth0Client({
        domain: data.domain,
        clientId: data.clientId,
        authorizationParams: {
          login_hint: emailValue,
          organization: data.organizationAuth0ID,
          redirect_uri: `${window.location.origin}/sign-in${
            code ? "" : `?organization=${currentOrganizationUID}`
          }`,
        },
      });

      if (errorDescription) {
        mainStore.toast.setErrorText(errorDescription);
      }
      if (!code) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'Auth0Client' is not assignable t... Remove this comment to see the full error message
        return setAuth0Client(auth0);
      }

      mainStore.toast.setInfoText("Processing...");
      await auth0.handleRedirectCallback();
      const token = await auth0.getTokenSilently();
      const success = await mainStore.auth0.callback(
        currentOrganizationUID,
        token,
      );
      if (!success) {
        return history.push(`${window.location.origin}/sign-in`);
      }

      await mainStore.webSessions.createWithSSOToken(token);
    })();
  }, [currentOrganizationUID]);

  useEffect(() => {
    (async () => {
      if (!auth0Client || !hasRedirectOnlySSOLogin) {
        return;
      }

      // @ts-expect-error TS(2339) FIXME: Property 'loginWithRedirect' does not exist on typ... Remove this comment to see the full error message
      await auth0Client.loginWithRedirect();
    })();
  }, [auth0Client, hasRedirectOnlySSOLogin]);

  // Functions
  async function handleSSOLogin() {
    setHasSSOButtonLoading(true);
    if (!auth0Client) {
      setHasSSOButtonLoading(false);
      return;
    }

    // @ts-expect-error TS(2339) FIXME: Property 'loginWithRedirect' does not exist on typ... Remove this comment to see the full error message
    await auth0Client.loginWithRedirect();
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    // Reset server side errors
    mainStore.webSessions.setWebSessionErrors({});

    // Get field name and the value
    const { name, value } = event.target;

    // Validate email as user types
    if (name === "email") {
      setEmailValue(value);
    }

    if (name === "password") {
      setPasswordValue(value);
    }
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setHasButtonLoading(true);

    if (hasEmailVerificationTemplate) {
      try {
        const response = await AuthAPI.getAuthenticationOptions(emailValue);
        setAuthErrorText("");
        if (
          response.authentication_options.includes(AUTHENTICATION_OPTION.SSO) &&
          response.authentication_options.includes(
            AUTHENTICATION_OPTION.EMAIL_PASSWORD,
          )
        ) {
          setAuthenticationOptions(response.authentication_options);
          setOrganizationUID(response.organization_uuid);
          setHasButtonLoading(false);
        } else if (
          response.authentication_options.includes(AUTHENTICATION_OPTION.SSO)
        ) {
          setOrganizationUID(response.organization_uuid);
          setHasRedirectOnlySSOLogin(true);
        } else if (
          response.authentication_options.includes(
            AUTHENTICATION_OPTION.EMAIL_PASSWORD,
          )
        ) {
          setAuthenticationOptions(response.authentication_options);
          setHasButtonLoading(false);
        }
      } catch (error) {
        window.console.log(`"Auth" error ${error}`);
        setHasButtonLoading(false);
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        if (error?.response?.status === 404) {
          setAuthErrorText("User not found.");
          mainStore.toast.setErrorText("Access Denied");
        } else {
          mainStore.toast.setErrorText("Error!");
        }
      }
    } else {
      await mainStore.webSessions.create(emailValue, passwordValue, rememberMe);
      setHasButtonLoading(false);
    }
  }

  return (
    <div className="check-in">
      <div className="check-in__wrap">
        <Header title="Sign In To Themis" />
        <div className="check-in__block">
          <div className="sign-in">
            <form onSubmit={handleSubmit}>
              {banner && (
                <p className="tw-mb-2 tw-rounded-md tw-bg-primary-25 tw-text-center tw-text-sm tw-font-semibold tw-text-primaryDim-300">
                  {banner}
                </p>
              )}
              {formattedErrors && (
                <p className="error-input">{formattedErrors}</p>
              )}
              <EmailInput
                placeholder="Company Email Address"
                name="email"
                onChange={handleInputChange}
                autoComplete="email"
                autoFocus
              />
              {!hasEmailVerificationTemplate && (
                <>
                  <PasswordInput
                    placeholder="Password"
                    name="password"
                    onChange={handleInputChange}
                    autoComplete="current-password"
                    autoFocus
                  />
                  <label className="tw-flex tw-cursor-pointer tw-items-center tw-gap-x-2 tw-text-xs tw-text-neutral-500">
                    <Checkbox
                      size="md"
                      color="primary"
                      checked={rememberMe}
                      onCheckedChange={(value: boolean) => setRememberMe(value)}
                    />
                    Keep me logged in
                  </label>
                </>
              )}
              <Flex justifyCenter>
                <Button
                  className={classNames("check-in__button", {
                    "button-loading": hasButtonLoading,
                  })}
                  label={
                    <Flex alignCenter>
                      <LoginProgressSpinner loading={hasButtonLoading} />
                      {!hasEmailVerificationTemplate ? "Sign In" : "Next"}
                    </Flex>
                  }
                  theme="primary"
                  disabled={shouldDisabledSubmitButton}
                />
              </Flex>
            </form>
            {!hasEmailVerificationTemplate && (
              <div className="forgot-password">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
            )}
            {hasEmailPasswordSSOTemplate && (
              <Flex alignCenter column className="sso-button-wrap">
                <hr className="sso-button-wrap__divider" />
                <Button
                  label={
                    <Flex alignCenter>
                      <LoginProgressSpinner loading={hasSSOButtonLoading} />{" "}
                      Sign In with SSO
                    </Flex>
                  }
                  className={classNames("sso-button-wrap__button", {
                    "button-loading": hasSSOButtonLoading,
                  })}
                  onClick={handleSSOLogin}
                />
              </Flex>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(SignIn);
