import { useEffect, useMemo } from "react";

import type { DataGridProps } from "../data-grid";
import { useDataGridState } from "../state/use-data-grid-state";
import type { ModelHasId } from "../types";
import type { UseDataGridBindingParams } from "./use-data-grid-binding";
import { useDataGridBinding } from "./use-data-grid-binding";

export type UseClientDataGridBindingParams<T extends object> = Omit<
  UseDataGridBindingParams<T>,
  "dataGridState" | "total" | "updateDataGridState"
>;

export function useClientDataGridBinding<T extends object>(
  data: T[] | undefined,
  // params are optional if the model has an id ( default getRowId method )
  ...parameterList: ModelHasId<T> extends true
    ? [UseClientDataGridBindingParams<T>?]
    : [UseClientDataGridBindingParams<T>]
) {
  const params = parameterList[0] ?? {};
  const { dataGridState, updateDataGridState } = useDataGridState();
  const { baseGridProps } = useDataGridBinding<T>({
    dataGridState,
    total: data?.length ?? 0,
    onDataGridStateChange: updateDataGridState,
    ...params,
  });

  const clientGridProps: DataGridProps<T> = useMemo(() => {
    return {
      ...baseGridProps,
      rowModelType: "clientSide",
      rows: data,
    };
  }, [baseGridProps, data]);

  return {
    clientGridProps,
  };
}
