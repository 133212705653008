import SvgIcon from "@mui/material/SvgIcon";
import { DownloadSimple, WarningCircle } from "@phosphor-icons/react";
import type { ReactElement } from "react";

import { Stack } from "../../../../layout/stack/stack";
import { ExternalLinkButton } from "../../../../navigation/external-link-button/external-link-button";
import { Typography } from "../../../typography/typography";
import type { MediaFile } from "../../types/media-file";

interface MediaErrorProps {
  file: MediaFile;
}

export function MediaError({ file }: MediaErrorProps): ReactElement {
  return (
    <Stack
      direction="column"
      alignItems="center"
      color="grey.400"
      spacing={2}
      textAlign="center"
    >
      <SvgIcon component={WarningCircle} fontSize="large" inheritViewBox />

      <Typography variant="h5">Failed to load {file.file_name}</Typography>

      <ExternalLinkButton
        startIcon={<DownloadSimple />}
        variant="text"
        color="inherit"
        href={file.file_url}
      >
        Download instead
      </ExternalLinkButton>
    </Stack>
  );
}
