import { ActivityTable } from "@themis/shared/components/activity-table/activity-table";
import {
  Breadcrumbs,
  Button,
  ConfirmationPopup,
  HeaderTabs,
  useToast,
} from "@themis/ui";
import React, { useEffect, useState } from "react";
import { PiChatCircleDotsBold } from "react-icons/pi";
import { useIntl } from "react-intl";
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { recordTypePath, type ShowAccountQueryParams } from "@/api";
import { useAccount, useUpdateAccount } from "@/api/queries/accounts";
import { CommentsSidebar } from "@/components/comments-sidebar/comments-sidebar";
import { useCommentsRouteParams } from "@/components/comments-sidebar/use-comments-route-params/use-comments-route-params";
import { ErrorContainer } from "@/components/ErrorContainer";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";
import { useIsFeatureEnabled } from "@/hooks/useIsFeatureEnabled";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

import { routes } from "../../routes";
import { AccountContacts } from "./account-contacts";
import { AccountContracts } from "./account-contracts";
import { AccountInfo } from "./account-info/account-info";
import AccountQuestionnaires from "./AccountQuestionnaires/AccountQuestionnaires";
import { ChildAccounts } from "./ChildAccounts";
import DueDiligence from "./due-diligence/due-diligence";

export type AccountLocationState = { from?: string } | undefined;

export function AccountDetails() {
  const toast = useToast();
  const history = useHistory();
  const { url } = useRouteMatch();

  const pageContentRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    pageContentRef.current?.scrollTo({ top: 0 });
  }, [url]);

  const { pathname, search } = useLocation();
  const workspaceId = useRouteWorkspaceId();

  const isAccountsActivityEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_ACCOUNTS_ACTIVITY,
  );

  const { accountId: _accountId } = useParams<{
    accountId: string;
  }>();
  const accountId = _accountId !== "new" ? parseInt(_accountId) : undefined;

  const searchParams = new URLSearchParams(search);
  const redirectTo = searchParams.get("redirectTo");

  const isNewAccount = !accountId;

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const queryParams: ShowAccountQueryParams = {
    expand: "contacts,contracts,child_accounts,documents",
  };

  const { formatMessage } = useIntl();

  const { open: openComments } = useCommentsRouteParams();

  const {
    data: accountData,
    isPending: isAccountPending,
    isError: isAccountError,
  } = useAccount({
    workspaceId,
    accountId,
    queryParams,
  });

  const account = accountData?.data;

  const { mutateAsync: updateAccount } = useUpdateAccount({
    accountId,
    workspaceId,
  });

  async function handleUpdateStatus() {
    setIsConfirmationOpen(false);

    try {
      const response = await updateAccount({
        name: account?.name || "",
        status: account?.status === "active" ? "inactive" : "active",
        website: account?.website || "",
      });

      toast({
        content: `Account "${account?.name}" has been ${
          response.data?.status === "active" ? "activated" : "deactivated"
        }!`,
        variant: "success",
      });

      if (redirectTo) {
        history.push(redirectTo);
      }
    } catch {
      toast({
        content: "Something went wrong. Could not update account status.",
        variant: "error",
      });
    }
  }

  const handleClickActivate = () => {
    setIsConfirmationOpen(true);
  };

  const handleCancelStatusUpdate = () => {
    setIsConfirmationOpen(false);
  };

  if (isAccountPending && !isNewAccount) {
    return (
      <PageContent>
        <Loading loadingLayout="small-table" />
      </PageContent>
    );
  }

  if (isAccountError) {
    return (
      <PageContent>
        <ErrorContainer
          backButtonProps={{
            linkTo: generatePath(routes.accounts.overview, {
              workspace_id: workspaceId,
            }),
          }}
        >
          {formatMessage({ defaultMessage: "Could not load account details" })}
        </ErrorContainer>
      </PageContent>
    );
  }

  const TABS = [
    {
      name: formatMessage({ defaultMessage: "Account Info" }),
      key: "",
      value: generatePath(`${url}`, {
        accountId,
      }),
    },
    {
      name: formatMessage({ defaultMessage: "Contracts" }),
      key: "contracts",
      value: generatePath(routes.accounts.detail.contracts.overview, {
        workspace_id: workspaceId,
        accountId: accountId ?? "new",
      }),
    },
    {
      name: formatMessage({ defaultMessage: "Sub Accounts" }),
      key: "sub-accounts",
      value: generatePath(routes.accounts.detail.subAccounts.overview, {
        workspace_id: workspaceId,
        accountId: accountId ?? "new",
      }),
    },
    {
      name: formatMessage({ defaultMessage: "Contacts" }),
      key: "contacts",
      value: generatePath(routes.accounts.detail.contacts.overview, {
        workspace_id: workspaceId,
        accountId: accountId ?? "new",
      }),
    },
    {
      name: formatMessage({ defaultMessage: "Questionnaires" }),
      key: "questionnaires",
      value: generatePath(routes.accounts.detail.questionnaires, {
        workspace_id: workspaceId,
        accountId: accountId ?? "new",
      }),
    },
    {
      name: formatMessage({ defaultMessage: "Due Diligence" }),
      key: "due-diligence",
      value: generatePath(routes.accounts.detail.dueDiligence, {
        workspace_id: workspaceId,
        accountId: accountId ?? "new",
      }),
    },
    ...(isAccountsActivityEnabled
      ? [
          {
            name: formatMessage({ defaultMessage: "Activity" }),
            key: "activity",
            value: generatePath(routes.accounts.detail.activity, {
              workspace_id: workspaceId,
              accountId: accountId ?? "new",
            }),
          },
        ]
      : []),
  ];

  return (
    <PageLayout>
      <Header
        HeaderOptions={
          <>
            {!isNewAccount && pathname === url && (
              <ConfirmationPopup
                anchor
                align="end"
                title={
                  account?.status === "active"
                    ? formatMessage({ defaultMessage: "Deactivate Account" })
                    : formatMessage({ defaultMessage: "Activate Account" })
                }
                content={formatMessage({
                  defaultMessage: "Are you sure you want to proceed?",
                })}
                open={isConfirmationOpen}
                onCancel={handleCancelStatusUpdate}
                onConfirm={handleUpdateStatus}
              >
                <Button color="tertiary" onClick={handleClickActivate}>
                  {account?.status === "active"
                    ? formatMessage({ defaultMessage: "Deactivate" })
                    : formatMessage({ defaultMessage: "Activate" })}
                </Button>
              </ConfirmationPopup>
            )}
            <Button
              color="tertiary"
              onClick={() => openComments(Number(accountId))}
            >
              <PiChatCircleDotsBold />
              {formatMessage({ defaultMessage: "Comments" })}
              {account?.comments_count ? ` (${account.comments_count})` : ""}
            </Button>
          </>
        }
        title={
          <Breadcrumbs
            breadcrumbItems={[
              {
                label: "Accounts",
                to: generatePath(routes.accounts.overview, {
                  workspace_id: workspaceId,
                }),
              },
              {
                label: isNewAccount ? "Untitled" : account?.name || "",
              },
            ]}
          />
        }
      />
      {!isNewAccount && (
        <div className="tw-flex tw-h-12 tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primaryDim-100 tw-bg-neutral-25 tw-px-5 tw-py-0">
          <HeaderTabs tabs={TABS} selectedTab={pathname} isLink />
        </div>
      )}

      {account && (
        <CommentsSidebar
          detailsPagePath={generatePath(routes.accounts.detail.recordView, {
            workspace_id: workspaceId,
            accountId: account.id,
          })}
          recordId={account.id}
          recordName={account.name}
          recordType={recordTypePath.accounts}
        />
      )}

      <PageContent ref={pageContentRef}>
        {isNewAccount || !account ? (
          <AccountInfo />
        ) : (
          <Switch>
            <Route path={routes.accounts.detail.contracts.overview}>
              <AccountContracts contracts={account.contracts!} />
            </Route>

            <Route path={routes.accounts.detail.subAccounts.overview}>
              <ChildAccounts childAccounts={account.child_accounts!} />
            </Route>

            <Route path={routes.accounts.detail.dueDiligence}>
              <DueDiligence />
            </Route>

            <Route path={routes.accounts.detail.activity}>
              <ActivityTable
                recordId={account.id}
                recordType={recordTypePath.accounts}
              />
            </Route>

            <Route path={routes.accounts.detail.contacts.overview}>
              <AccountContacts contacts={account.contacts!} />
            </Route>

            <Route path={routes.accounts.detail.questionnaires}>
              <AccountQuestionnaires
                accountId={account.id}
                workspaceId={workspaceId}
              />
            </Route>

            <Route path="">
              <AccountInfo account={account} />
            </Route>
          </Switch>
        )}
      </PageContent>
    </PageLayout>
  );
}
