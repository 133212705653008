import type { DataWithIdAndCustomFields } from "@themis/ui";
import { isEmpty, isEqual } from "lodash";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router";

import type { RecordTypePath } from "@/api";
import { useSearchParams } from "@/hooks/useSearchParams";

import { getFiltersFromLocalStorage } from "../get-filters-from-local-storage/get-filters-from-local-storage";
import { parseParams } from "../parse-params/parse-params";
import { saveFiltersToLocalStorage } from "../save-filters-to-local-storage/save-filters-to-local-storage";
import type { FilterFieldData } from "../types";

interface UseFiltersSortValuesParams<T extends DataWithIdAndCustomFields> {
  fieldsData: FilterFieldData<T>;
  recordType: RecordTypePath;
}

export function useFiltersSortValues<T extends DataWithIdAndCustomFields>({
  recordType,
  fieldsData,
}: UseFiltersSortValuesParams<T>) {
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { workspace_id: workspaceId } = useParams<{ workspace_id: string }>();

  const {
    filters: filtersFromStorage,
    listRequestQueryParams: { filters: listRequestQueryParamsFilters },
  } = getFiltersFromLocalStorage({ recordType, workspaceId, fieldsData });

  const {
    filters: filtersFromSearchParams,
    sorting,
    listRequestQueryParams: listRequestQueryParamsFromSearch,
  } = parseParams(new URLSearchParams(search), fieldsData);

  const shouldUseLocalStorage = isEmpty(filtersFromSearchParams);

  useEffect(() => {
    if (
      shouldUseLocalStorage &&
      !isEqual(filtersFromSearchParams, filtersFromStorage)
    ) {
      setSearchParams(
        { ...searchParams, filters: listRequestQueryParamsFilters },
        true,
      );
    }

    if (
      !shouldUseLocalStorage &&
      !isEqual(filtersFromSearchParams, filtersFromStorage)
    ) {
      saveFiltersToLocalStorage({
        filters: filtersFromSearchParams,
        recordType,
        workspaceId,
      });
    }
  }, [
    filtersFromStorage,
    searchParams,
    setSearchParams,
    filtersFromSearchParams,
    shouldUseLocalStorage,
    recordType,
    listRequestQueryParamsFilters,
    workspaceId,
  ]);

  const filters = shouldUseLocalStorage
    ? filtersFromStorage
    : filtersFromSearchParams;
  const listRequestQueryParams = shouldUseLocalStorage
    ? {
        ...listRequestQueryParamsFromSearch,
        filters: listRequestQueryParamsFilters,
      }
    : listRequestQueryParamsFromSearch;

  return { filters, sorting, listRequestQueryParams };
}
