import { DynamicValue } from "@themis/shared/components/fields/dynamic-value/dynamic-value";
import { Label, useToast } from "@themis/ui";
import React from "react";
import { useIntl } from "react-intl";
import { generatePath, useHistory, useLocation } from "react-router-dom";

import type {
  Account,
  CreateAccountMutationRequest,
  ModifyAccountRequest,
} from "@/api";

import {
  useCreateAccount,
  useUpdateAccount,
} from "../../../../../api/queries/accounts/accounts";
import { useCompany } from "../../../../../api/queries/companies";
import { useLayoutFields } from "../../../../../api/utils/fields/use-layout-fields";
import { formatDynamicFormValuesToRequest } from "../../../../../components/dynamic-field/utils/format-dynamic-form-values-to-request";
import { useRouteWorkspaceId } from "../../../../../hooks/use-route-workspace-id";
import { useSearchParams } from "../../../../../hooks/useSearchParams";
import { AccountInfoForm } from "../../../components/AccountInfo/account-info-form/AccountInfoForm";
import type { AccountInfoSchema } from "../../../components/AccountInfo/account-info-form/use-validation-schema";
import { routes } from "../../../routes";
import { useRouteAccountId } from "../../../utils/use-route-account-id";
import type { AccountLocationState } from "../AccountDetails";
import { DocumentsPreview } from "./documents-preview/documents-preview";
import { LinkedRecordsPreview } from "./linked-records-preview/linked-records-preview";
import { ReferencedInPreview } from "./referenced-in-preview/referenced-in-preview";

function FormContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-min-w-96 tw-flex-col tw-gap-8 tw-px-32">
      {children}
    </div>
  );
}

function StatusContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-bg-neutral-50 tw-px-2.5 tw-py-1.5">
      {children}
    </div>
  );
}

function AccountInfoContainer({ children }: { children: React.ReactNode }) {
  return <div className="tw-flex tw-flex-col tw-gap-2">{children}</div>;
}

export function AccountInfo({ account }: { account?: Account }) {
  const toast = useToast();
  const history = useHistory();
  const { formatMessage } = useIntl();

  const { data: company } = useCompany("current");
  const companyId = Number(company?.data.company.id);

  const workspaceId = useRouteWorkspaceId();
  const accountId = useRouteAccountId();

  const isNewAccount = !account;

  const recordType = "accounts";

  const { state: locationState } = useLocation<AccountLocationState>();

  const { customFields } = useLayoutFields({
    companyId,
    recordType,
    workspaceId,
    layoutType: "detail",
  });

  /**
   * If exists, redirect to the specified URL after saving the account
   * (e.g. when creating an account from the vendor page, redirect back to the vendor page)
   */
  const [{ redirectTo }] = useSearchParams<{ redirectTo?: string }>();

  const accountsListPath = generatePath(routes.accounts.overview, {
    workspace_id: workspaceId,
  });

  const { mutateAsync: createAccount } = useCreateAccount({
    workspaceId,
  });

  const { mutateAsync: updateAccount } = useUpdateAccount({
    workspaceId,
    accountId,
  });

  function getFromLocation(newAccountId?: number) {
    if (isNewAccount) {
      return redirectTo
        ? `${redirectTo}?account_id=${newAccountId}`
        : accountsListPath;
    }

    return redirectTo || locationState?.from || accountsListPath;
  }

  async function handleCreateAccount(
    newAccountData: CreateAccountMutationRequest,
  ) {
    try {
      const response = await createAccount(newAccountData);

      history.push(getFromLocation(response.data?.id));

      toast({
        content: `Account "${newAccountData.account.name}" has been added!`,
        variant: "success",
      });
    } catch {
      toast({
        content: "Something went wrong. Could not create account.",
        variant: "error",
      });
    }
  }

  async function handleUpdateAccount(newAccountData: ModifyAccountRequest) {
    try {
      await updateAccount(newAccountData);

      history.push(getFromLocation());

      toast({
        content: `Account "${newAccountData.name}" has been updated!`,
        variant: "success",
      });
    } catch {
      toast({
        content: "Something went wrong. Could not update account.",
        variant: "error",
      });
    }
  }

  function handleSubmitForm(values: AccountInfoSchema) {
    const newAccountData: ModifyAccountRequest = {
      name: values.name,
      account_types: values.account_types,
      logo: values.logo?.signedId ?? undefined,
      description: values.description,
      mailing_city: values.mailing_city,
      mailing_postal_code: values.mailing_postal_code,
      legal_name: values.legal_name,
      website: values.website,
      phone: values.phone,
      mailing_street: values.mailing_street,
      mailing_state: values.mailing_state,
      mailing_country: values.mailing_country,
      account_owner_id: values.account_owner_id,
      main_contact_id: values.main_contact_id,
      custom_fields: formatDynamicFormValuesToRequest(
        customFields ?? [],
        values.customFields ?? {},
      ),
    };

    if (!account) {
      handleCreateAccount({ account: newAccountData });
    } else {
      handleUpdateAccount(newAccountData);
    }
  }

  return (
    <FormContainer>
      <div className="tw-flex tw-flex-row tw-gap-2">
        <StatusContainer>
          <Label
            id="status-label"
            className="tw-font-semibold tw-text-neutral-500"
          >
            {formatMessage({
              defaultMessage: "Status",
              description: "Record status label",
            })}
          </Label>
          {account ? (
            <DynamicValue
              record={account}
              fieldPath="status"
              recordType="accounts"
            />
          ) : (
            formatMessage({
              defaultMessage: "N/A",
            })
          )}
        </StatusContainer>
        <StatusContainer>
          <Label className="tw-font-semibold tw-text-neutral-500">
            {formatMessage({
              defaultMessage: "Parent Account",
            })}
          </Label>
          <p className="tw-text-neutral-300">
            {account?.parent?.name ||
              formatMessage({
                defaultMessage: "N/A",
              })}
          </p>
        </StatusContainer>
      </div>
      <AccountInfoContainer>
        <h2 className="tw-text-lg tw-font-semibold tw-text-neutral-500">
          {formatMessage({
            defaultMessage: "Account Info",
            description: "Account info section title",
          })}
        </h2>

        {customFields && (
          <AccountInfoForm
            account={account}
            contacts={account?.contacts}
            customFields={customFields}
            onSubmit={handleSubmitForm}
          />
        )}
      </AccountInfoContainer>
      <DocumentsPreview account={account} />
      {!isNewAccount && accountId !== undefined && (
        <>
          <LinkedRecordsPreview
            accountId={accountId}
            disabled={account?.status === "inactive"}
          />
          <ReferencedInPreview
            accountId={accountId}
            disabled={account?.status === "inactive"}
          />
        </>
      )}
    </FormContainer>
  );
}
