import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { IndexParams, ModuleStore } from "../types/module-store-types";
import type {
  LibraryRecord,
  RiskRegistersDataProps,
  ScoringMatrixRating,
} from "../types/risk-register-types";

const EMPTY_CONTROLS_DATA = {
  modules: [],
  rating: {},
  description: {},
  risk_event: {},
  changed: false,
};

const EMPTY_OPERATIONAL_CONTROLS_DATA = {
  selectedIDs: [],
  changed: false,
};

interface Section {
  id?: number;
}
interface LibraryItem {
  risk_type?: { id: number };
  main_category?: { id: number };
  risk_events?: string;
  description?: string;
  tags?: { id: Array<number> };
  id?: number;
  reference?: string;
}

export default class RiskRegistersStore implements ModuleStore {
  mainStore: MainStore;

  // Observable objects
  activeScreen = "";
  data: RiskRegistersDataProps = {
    scoring_matrices: [],
    scoring_matrix_range_ratings: [],
    record_versions: [],
  };
  libraryItem: LibraryItem = {};
  libraryRecords: Array<LibraryRecord> = [];
  versionHistoryRecords = [];
  controlsData = EMPTY_CONTROLS_DATA;
  aggregateScores = [];
  operationalControlsData = EMPTY_OPERATIONAL_CONTROLS_DATA;
  // if you are in a single section  view, this is the section
  currentSection: Section = {};
  // the section is the recipient of a given action.
  // For example, you can apply a library event to a section that you are not currently on.
  selectedSectionTagID = null;
  controller: AbortController | null = null;
  loading = false;
  scoringMatrixRatings: Array<ScoringMatrixRating> = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      libraryRecords: observable,
      versionHistoryRecords: observable,
      controlsData: observable,
      aggregateScores: observable,
      operationalControlsData: observable,
      libraryItem: observable,
      currentSection: observable,
      selectedSectionTagID: observable,
      activeScreen: observable,
      loading: observable,
      scoringMatrixRatings: observable,
      setActiveScreen: action,
      setData: action,
      setLibraryRecords: action,
      setVersionHistoryRecords: action,
      setControlsData: action,
      setAggregateScores: action,
      resetControlsData: action,
      setOperationalControlsData: action,
      resetOperationalControlsData: action,
      setLibraryItem: action,
      setCurrentSection: action,
      setSelectedSectionTagID: action,
      unlock: action,
      setScoringMatrixRatings: action,
    });

    this.mainStore = mainStore;
  }

  async index({
    workspaceID,
    tab = this.activeScreen,
    sortParams,
    tableFilters = [],
  }: IndexParams) {
    try {
      if (!workspaceID) {
        return;
      }

      const params = {
        tab,
        table_filters: tableFilters,
        sort_field_name: sortParams?.field_name,
        sort_direction: sortParams?.direction,
      };

      this.loading = true;
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/workspaces/${workspaceID}/risk_registers`,
        headers: this.mainStore.getHeaders(),
        params,
      });
      this.loading = false;

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        return;
      }

      this.setData(response.data);
      this.mainStore.fields.setList(response.data.fields);
      this.setScoringMatrixRatings(response.data.scoring_matrix_ratings);
      this.fetchAggregateScores(this.mainStore.context.moduleWorkspaceID);
    } catch (error) {
      window.console.log(`"RiskRegisters#Index for Company" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'workspaceID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async create(workspaceID, sectionTagID) {
    const params = { section_tag_id: sectionTagID };
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/workspaces/${workspaceID}/risk_registers`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      this.setData({
        ...this.data,
        record_versions: [
          response.data.record_version,
          ...this.data.record_versions,
        ],
      });

      return response.data.record_version;
    } catch (error) {
      window.console.log(`"RiskRegisters#create" error ${error}`);
    }
  }

  async duplicateRiskRegisterLibraryItem(
    moduleWorkspaceID: number,
    ids: number[],
  ) {
    const params = { module_workspace_id: moduleWorkspaceID, ids };
    try {
      const response = await legacyApi({
        method: "POST",
        url: "/api/react/risk_register_library_records/duplicate_to_templates",
        headers: this.mainStore.getHeaders(),
        data: params,
      });
      // @ts-expect-error TS(2339) FIXME: Property 'sort_field_name' does not exist on type ... Remove this comment to see the full error message
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(
          // @ts-expect-error TS(2339) FIXME: Property 'sort_field_name' does not exist on type ... Remove this comment to see the full error message

          response.response,
          // @ts-expect-error TS(2304) FIXME: Cannot find name 'defaultMessage'.
          (defaultMessage =
            "Something went wrong. Please contact support@themis.com"),
        );
      } else {
        this.mainStore.toast.setText("Library Items Duplicated To Templates");

        this.setActiveScreen("Templates");
      }
    } catch (err) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      this.mainStore.toast.setErrorText(err);
      window.console.log(
        `"RiskRegisters#duplicateRiskRegisterLibraryItem" error ${err}`,
      );
    }
  }

  async unlock(recordVersionID: number) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/risk_registers/${recordVersionID}/unlock`,
        headers: this.mainStore.getHeaders(),
      });
      // @ts-expect-error asdf
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(response);
      }

      this.fetchAggregateScores(this.mainStore.context.moduleWorkspaceID);
      return response.data;
    } catch (error) {
      window.console.log(`"RiskRegisters#unlock" error ${error}`);
    }
  }
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/risk_registers/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"RiskRegisters#delete" error ${error}`);
    }
  }

  async updateMatrixRatings(
    moduleWorkspaceID: number | null,
    matrixRatings: ScoringMatrixRating[],
    riskType: string,
    isRange: boolean,
  ) {
    const data = {
      matrix_ratings: matrixRatings,
      risk_type: riskType,
      is_range: isRange,
    };
    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/risk_registers/matrix_ratings`,
        headers: this.mainStore.getHeaders(),
        data,
      });
      await this.index({ workspaceID: this.mainStore.context.workspaceID });
    } catch (error) {
      window.console.log(`"RiskRegisters#updateMatrixRatings" error ${error}`);
    }
  }

  // @ts-expect-error TS(7031) FIXME: Binding element 'moduleWorkspaceID' implicitly has... Remove this comment to see the full error message
  async completedOnDate({ date }) {
    const params = { section_tag_id: this.currentSection?.id, date };
    const activeWorkspaceID = this.mainStore.context.workspaceID;
    if (!activeWorkspaceID) {
      return;
    }
    this.loading = true;
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/workspaces/${activeWorkspaceID}/risk_registers/completed_on_date`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      this.loading = false;
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        //  @ts-expect-error asdf
        this.mainStore.toast.setErrorFromResponse(response.response);
      }

      this.mainStore.recordVersions.setList(response.data.record_versions);
    } catch (error) {
      this.mainStore.toast.setErrorFromResponse(error);

      window.console.log(
        `"RiskRegisters#completedOnDate for date" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async updateSection(recordVersionID, sectionTagID) {
    const data = { section_tag_id: sectionTagID, identifier: "risk_register" };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/risk_registers/${recordVersionID}/update_section`,
        headers: this.mainStore.getHeaders(),
        data,
      });
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response?.isAxiosError && response?.message.includes("403")) {
        this.mainStore.toast.setErrorText("Cannot reorder a published record.");
      }
    } catch (error) {
      window.console.log(`"Policies#update-section" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async fetchLibrary(moduleWorkspaceID, libraryType) {
    const params = { library_type: libraryType };
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/risk_registers/library`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      this.setLibraryRecords(response.data.library_items);
    } catch (error) {
      window.console.log(`"RiskRegisters#fetchLibrary" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async createBulk(moduleWorkspaceID, ids, sectionTagID, libraryType) {
    const data = {
      ids,
      section_tag_id: sectionTagID,
      library_type: libraryType,
    };

    if (!sectionTagID) {
      this.mainStore.toast.setErrorText("Section Tag ID is required");
      return;
    }

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/risk_registers/bulk`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setData({
        ...this.data,
        record_versions: [
          ...response.data.record_versions,
          ...this.data.record_versions,
        ],
      });

      this.setCurrentSection(null);
    } catch (error) {
      window.console.log(`"RiskRegisters#createBulk" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async fetchControlsData(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/record_versions/${recordVersionID}/controls/modules`,
        headers: this.mainStore.getHeaders(),
      });

      this.setControlsData(response.data, true);
    } catch (error) {
      window.console.log(`"RiskRegisters#fetchControlsData" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async saveControlsData(recordVersionID) {
    const { modules, rating, description } = this.controlsData;

    const data = {
      controls_data: {
        records_ids: modules
          // @ts-expect-error TS(2339) FIXME: Property 'checked' does not exist on type 'never'.
          .filter((item) => item.checked && item.selected_ids.length)
          // @ts-expect-error TS(2339) FIXME: Property 'selected_ids' does not exist on type 'ne... Remove this comment to see the full error message
          .map((item) => item.selected_ids)
          .flat(),
        // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type '{}'.
        rating_value: rating?.value,
        // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type '{}'.
        description_value: description?.value || "",
      },
    };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/record_versions/${recordVersionID}/controls/modules`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (!response.isAxiosError) {
        const { record_version: recordVersion } = response.data;
        this.mainStore.recordVersions.setList(
          this.mainStore.recordVersions.list.map((item) =>
            item.id === recordVersionID ? recordVersion : item,
          ),
        );
        this.setControlsData(this.controlsData, true);
        this.mainStore.toast.setInfoText("Controls data saved!");
      }
    } catch (error) {
      window.console.log(`"RiskRegisters#saveControlsData" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async fetchOperationalControlsData(recordVersionID, allStatus = null) {
    const params = { all_status: allStatus };

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/record_versions/${recordVersionID}/controls/operational`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      return response.data;
    } catch (error) {
      window.console.log(
        `"RiskRegisters#fetchOperationalControlsData" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async saveOperationalControls(recordVersionID) {
    const data = {
      controls_data: {
        record_versions_ids: this.operationalControlsData.selectedIDs,
      },
    };

    try {
      const res = await legacyApi({
        method: "POST",
        url: `${API_URL}/record_versions/${recordVersionID}/controls/operational`,
        headers: this.mainStore.getHeaders(),
        data,
      });
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (!res.isAxiosError) {
        const { record_version: recordVersion } = res.data;
        this.mainStore.recordVersions.setList(
          this.mainStore.recordVersions.list.map((item) =>
            item.id === recordVersionID ? recordVersion : item,
          ),
        );
        this.setOperationalControlsData(
          this.operationalControlsData.selectedIDs,
          false,
        );
        this.mainStore.toast.setInfoText("Operational Controls data saved!");
      }
    } catch (error) {
      window.console.log(
        `"RiskRegisters#saveOperationalControls" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async updateScoringMatrix(moduleWorkspaceID, matrix) {
    const data = { matrix };
    try {
      const res = await legacyApi({
        method: "PUT",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/risk_registers/matrix`,
        headers: this.mainStore.getHeaders(),
        data,
      });
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (res.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
        this.mainStore.toast.setErrorText(res.response.data.error);
        return false;
      }

      const { scoring_matrix } = res.data;
      this.setData({
        ...this.data,
        scoring_matrices: this.data.scoring_matrices.map((item) =>
          item.id === scoring_matrix.id ? scoring_matrix : item,
        ),
      });
    } catch (error) {
      window.console.log(`"RiskRegisters#updateScoringMatrix" error ${error}`);
    }
  }

  async updateScoringMatrixCell(
    moduleWorkspaceID: number | null,
    scoringMatrixCellID: number,
    scoringMatrixRatingID: number | undefined,
  ) {
    const data = {
      scoring_matrix_cell_id: scoringMatrixCellID,
      scoring_matrix_rating_id: scoringMatrixRatingID,
    };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/risk_registers/matrix_cell`,
        headers: this.mainStore.getHeaders(),
        data,
      });
      return response.data.scoring_matrix;
    } catch (error) {
      window.console.log(
        `"RiskRegisters#updateScoringMatrixCell" error ${error}`,
      );
    }
  }

  async approve(recordVersionID: number) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/risk_registers/${recordVersionID}/approve`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError && response.response?.data?.cells_errors) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          response.response.data.cells_errors,
        );
        return;
      }
    } catch (error) {
      window.console.log(`"RiskRegisters#approve" error ${error}`);
    }
  }

  async finalize(recordVersionID: number) {
    try {
      const result = await legacyApi({
        method: "PUT",
        url: `${API_URL}/risk_registers/${recordVersionID}/finalize`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
        return;
      }

      this.fetchAggregateScores(this.mainStore.context.moduleWorkspaceID);
      return true;
    } catch (error) {
      window.console.log(`"RiskRegisters#finalize" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async fetchVersionHistory(recordVersionID) {
    const params = { identifier: "risk_register" };
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/risk_registers/${recordVersionID}/historical`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      this.setVersionHistoryRecords(response.data.record_versions);
    } catch (error) {
      window.console.log(`"RiskRegisters#fetchVersionHistory" error ${error}`);
    }
  }

  async fetchAggregateScores(moduleWorkspaceID: number | null) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/risk_registers/aggregate_scores`,
        headers: this.mainStore.getHeaders(),
      });
      this.setAggregateScores(response.data.aggregate_scores);
    } catch (error) {
      window.console.log(`"RiskRegisters#fetchAggregateScores" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async createLibraryItem(moduleWorkspaceID, params) {
    params.tag_ids = params.framework;
    delete params.framework;
    const data = { data: params };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/risk_registers/library_items`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      this.setLibraryRecords([...this.libraryRecords, response.data.item]);
      this.setLibraryItem({});
      return response.data.item;
    } catch (error) {
      window.console.log(`"RiskRegisters#createLibraryItem" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async updateLibraryItem(moduleWorkspaceID, id, params) {
    params.tag_ids = params.framework;
    delete params.framework;
    const data = { data: params };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/risk_registers/library_items/${id}`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      this.setLibraryRecords(
        this.libraryRecords.map((item) =>
          item.id === id ? response.data.item : item,
        ),
      );
      this.setLibraryItem({});
      return response.data.item;
    } catch (error) {
      window.console.log(`"RiskRegisters#updateLibraryItem" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async deleteLibraryItem(moduleWorkspaceID, id) {
    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/risk_registers/library_items/${id}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      this.setLibraryRecords(
        this.libraryRecords.filter((item) => item.id !== id),
      );
    } catch (error) {
      window.console.log(`"RiskRegisters#deleteLibraryItem" error ${error}`);
    }
  }

  async exportLibrary(
    moduleWorkspaceID: number | null,
    sectionsRecordIds: number[],
  ) {
    const data = { risk_register_library_record_ids: sectionsRecordIds };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/risk_registers/library/export`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      return response.data;
    } catch (error) {
      window.console.log(`"RiskRegisters#exportLibrary" error ${error}`);
    }
  }

  async importLibrary(moduleWorkspaceID: number | null, file: File) {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/risk_registers/library/import`,
        headers: {
          ...this.mainStore.getHeaders(),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      this.fetchLibrary(moduleWorkspaceID, "Templates");
    } catch (error) {
      window.console.log(`"RiskRegisters#importLibrary" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async exportHistory(moduleWorkspaceID) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/risk_registers/history/export`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      return response.data;
    } catch (error) {
      window.console.log(`"RiskRegisters#exportHistory" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async downloadLibraryTemplate(moduleWorkspaceID) {
    try {
      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/risk_registers/library/template`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.toast.setInfoText(
          "An error occurred. Please try again or contact Themis support if the problem persists.",
        );
        return;
      }

      return result.data;
    } catch (error) {
      window.console.log(
        `"RiskRegisters#downloadLibraryTemplate" error ${error}`,
      );
    }
  }

  async createBulkCompanyLibraryItems(
    moduleWorkspaceID: number,
    ids: number[],
  ) {
    const params = { ids };
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/risk_registers/create_bulk_company_library_items`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(
          response.response,
          // @ts-expect-error TS(2304) FIXME: Cannot find name 'defaultMessage'.
          (defaultMessage =
            "Something went wrong. Please contact support@themis.com"),
        );
      } else {
        this.mainStore.toast.setText("Items added to Company Library");
      }
    } catch (err) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      this.mainStore.toast.setErrorText(err);
      window.console.log(
        `"RiskRegisters#createBulkCompanyLibraryItems" error ${err}`,
      );
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setData(value, updateRVsList = true) {
    this.data = value;

    if (updateRVsList) {
      this.mainStore.recordVersions.setList(this.data.record_versions);
    }
  }

  setLibraryRecords(value: Array<LibraryRecord>) {
    this.libraryRecords = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setVersionHistoryRecords(value) {
    this.versionHistoryRecords = value;
  }

  setAggregateScores(aggregateScores: []) {
    this.aggregateScores = aggregateScores;
  }

  // @ts-expect-error asdf
  setControlsData(value, initialRequest = false) {
    this.controlsData = { ...value, changed: !initialRequest };
  }

  resetControlsData() {
    this.controlsData = EMPTY_CONTROLS_DATA;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  setOperationalControlsData(ids, changed = true) {
    this.operationalControlsData = { selectedIDs: ids, changed };
  }

  resetOperationalControlsData() {
    this.operationalControlsData = EMPTY_OPERATIONAL_CONTROLS_DATA;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setLibraryItem(value) {
    this.libraryItem = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrentSection(value) {
    this.currentSection = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setSelectedSectionTagID(value) {
    this.selectedSectionTagID = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setScoringMatrixRatings(value) {
    this.scoringMatrixRatings = value;
  }

  // what, why, how does this have to be an arrow function
  setActiveScreen = (value: string) => {
    this.activeScreen = value;
  };

  cleanup() {
    this.setData({});
    this.setLibraryRecords([]);
    this.resetControlsData();
    this.resetOperationalControlsData();
    this.setLibraryItem({});
    this.setCurrentSection(null);
    this.setSelectedSectionTagID(null);
  }

  abort() {
    this.controller?.abort();
  }
}
