import type { ThemeOptions } from "@mui/material";

export const dialogTitle: ThemeOptions["components"] = {
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(2, 2, 0, 2),
        fontWeight: theme.typography.h4.fontWeight,
        fontSize: theme.typography.h4.fontSize,
      }),
    },
  },
};
