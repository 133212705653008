import { useCallback } from "react";

import { useSearchParams } from "@/hooks/useSearchParams";

export function useCommentsRouteParams() {
  const [params, setParams] = useSearchParams();

  const isOpen = !!params.showCommentsForRecord;

  const close = useCallback(() => {
    setParams({
      ...params,
      showCommentsForRecord: undefined,
      commentsTab: undefined,
      commentId: undefined,
    });
  }, [setParams, params]);

  const open = useCallback(
    (recordId: number) => {
      setParams({ ...params, showCommentsForRecord: recordId.toString() });
    },
    [setParams, params],
  );

  return {
    close,
    isOpen,
    open,
    selectedRecordId: params.showCommentsForRecord
      ? Number(params.showCommentsForRecord)
      : undefined,
  };
}
