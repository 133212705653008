import { DownloadSimple } from "@phosphor-icons/react";
import { ExternalLinkButton } from "@themis/ui-library/components/navigation/external-link-button/external-link-button";
import type { ReactElement } from "react";
import { useIntl } from "react-intl";

import { useInteractiveMediaContext } from "../../interactive-media.context";

export function DownloadButton(): ReactElement {
  const { formatMessage } = useIntl();
  const { file } = useInteractiveMediaContext();

  return (
    <ExternalLinkButton
      variant="text"
      color="inherit"
      href={file.file_url}
      startIcon={<DownloadSimple />}
    >
      {formatMessage({
        defaultMessage: "Download",
      })}
    </ExternalLinkButton>
  );
}
