import { faker } from "@faker-js/faker";

import type {
  SendAttestation202,
  SendAttestationMutationRequest,
  SendAttestationMutationResponse,
  SendAttestationPathParams,
} from "../models/SendAttestation";

export function createSendAttestationPathParams(): NonNullable<SendAttestationPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description Job to send attestation has been created
 */
export function createSendAttestation202(): NonNullable<SendAttestation202> {
  return { data: { job_id: faker.string.alpha() } };
}

export function createSendAttestationMutationRequest(): NonNullable<SendAttestationMutationRequest> {
  return { user_ids: faker.helpers.arrayElements([faker.number.int()]) as any };
}

/**
 * @description Job to send attestation has been created
 */
export function createSendAttestationMutationResponse(): NonNullable<SendAttestationMutationResponse> {
  return { data: { job_id: faker.string.alpha() } };
}
