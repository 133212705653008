import { useFocusedComment } from "@themis/shared/components/comments/use-focused-comment/use-focused-comment";
import { ClickAwayListener } from "@themis/ui-library/components/utils/click-away-listener/click-away-listener";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";

import EditCommentReply from "@/components/settings/notificationsCenter/tasks/EditCommentReply";
import { ReplyHeader } from "@/components/settings/notificationsCenter/tasks/ReplyHeader";
import type { CommentTab } from "@/components/table/shared/comments/CommentsSlideMenu/types";
import { useMainStore } from "@/contexts/Store";
import { useSearchParams } from "@/hooks/useSearchParams";
import type { UserComment } from "@/stores/types/comment-types";

export function CommentReply({ reply }: { reply: UserComment }) {
  const mainStore = useMainStore();
  const [replyEditId, setReplyEditId] = useState<number | null>(null);

  const { getIsActive, resetFocused, scrollToWhenActive } = useFocusedComment();

  const [searchParams] = useSearchParams<{
    comments_tab?: CommentTab;
  }>();

  const isInternalTab = searchParams.comments_tab === "internal";

  async function handleDeleteReply(id: number) {
    await mainStore.comments.delete(id);
  }

  function handleEditCommentClick(id: number) {
    setReplyEditId(id);
  }

  const handleClickAway = () => {
    if (getIsActive(reply.id)) {
      resetFocused();
    }
  };

  return (
    <ClickAwayListener key={reply.id} onClickAway={handleClickAway}>
      <div
        ref={(node) => scrollToWhenActive(node, reply.id)}
        className={classNames("comment-reply-container", {
          "comment-reply-container--active": getIsActive(reply.id),
        })}
      >
        <ReplyHeader
          comment={reply}
          onConfirmDelete={handleDeleteReply}
          onEditCommentClick={handleEditCommentClick}
        />
        <EditCommentReply
          key={[replyEditId, reply.content].join()}
          isPrivate={isInternalTab}
          reply={reply}
          replyEditId={replyEditId}
          setReplyEditId={setReplyEditId}
        />
      </div>
    </ClickAwayListener>
  );
}

export default observer(CommentReply);
