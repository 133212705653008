import type { Review } from "@themis/api/gen/models/review";
import { Chip } from "@themis/ui-library/components/data-display/chip/chip";
import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { Card } from "@themis/ui-library/components/surfaces/card/card";
import type { ThemisPaletteColor } from "@themis/ui-library/theme/types/themis-palette";
import type { ReactElement } from "react";
import { useIntl } from "react-intl";

import UserAvatar from "@/components/UserAvatar";

import { getReviewStatusColor } from "../../reviews/utils/get-review-status-color";
import { reviewStatusLabelMessages } from "../../reviews/utils/review-status-label-messages";
import type { CommentWithUser } from "../comment-with-user";
import { CommentContent } from "../comment/comment-content/comment-content";

interface ReviewCommentProps {
  comment: CommentWithUser & {
    review: Review;
  };
}

export function ReviewComment({ comment }: ReviewCommentProps): ReactElement {
  const { formatDate, formatMessage } = useIntl();

  const statusColor: ThemisPaletteColor = getReviewStatusColor(
    comment.review.review_type,
  );
  const statusLabel = formatMessage(
    reviewStatusLabelMessages[comment.review.review_type],
  );

  return (
    <Card
      component="article"
      elevation={0}
      sx={(theme) => ({
        padding: 1,
        backgroundColor: theme.palette[statusColor].lightest,
      })}
    >
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center">
            <UserAvatar user={comment.user} />
            <Stack spacing={0}>
              <Typography variant="subtitle2">
                {comment.user?.full_name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {formatDate(comment.created_at, {
                  dateStyle: "medium",
                  timeStyle: "short",
                })}
              </Typography>
            </Stack>
          </Stack>
          <Chip color={statusColor} label={statusLabel} />
        </Stack>

        <CommentContent comment={comment} />
      </Stack>
    </Card>
  );
}
