import type { DataWithIdAndCustomFields } from "@themis/ui";

export interface FilterValueOption {
  value: string;
  key: string;
}

export interface FilterData {
  displayName: string;
  type: "string";
  options?: FilterValueOption[];
}

export type FilterFieldData<T> = Partial<Record<keyof T, FilterData>>;

export enum FilterOperand {
  ANY = "any",
  NOT = "not",
  MATCH = "match",
  PRESENT = "present",
  MISSING = "missing",
}

export type Filter =
  | {
      operand: FilterOperand.ANY | FilterOperand.NOT | FilterOperand.MATCH;
      value: string[];
    }
  | {
      operand: FilterOperand.PRESENT | FilterOperand.MISSING;
      value?: never;
    };

export type Filters<T> = Partial<Record<keyof T, Filter>>;

export interface Sorting<T extends DataWithIdAndCustomFields> {
  columnKey: keyof T | keyof T["custom_fields"];
  direction: "asc" | "desc";
}
