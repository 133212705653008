import { isEqual } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";

import { CREATE_RISK_METHODOLOGY_DEFAULTS as DEFAULTS } from "@/features/risk-assessment";
import FlexDashboardContent from "@/features/risk-assessment/components/FlexDashboardContent/FlexDashboardContent";
import type {
  ResidualRisk,
  ResidualRiskMatrix,
  RiskRating,
} from "@/features/risk-assessment/types/risk-methodology";
import { usePreventUnsavedChanges } from "@/hooks/usePreventUnsavedChanges";

import { RiskMethodologyAPI } from "../../../api/legacy/risk-assessment/RiskMethodologiesApi";
import ViewModuleUsers from "../../../components/dashboard/ViewModuleUsers";
import { Button } from "../../../components/Elements";
import DashboardHeader from "../../../components/shared/DashboardHeader";
import { useMainStore } from "../../../contexts/Store";
import FlexDashboardContentWrapper from "../components/FlexDashboardContentWrapper/FlexDashboardContentWrapper";
import RiskMethodologyBuilder from "../components/RiskMethodology/RiskMethodologyBuilder/RiskMethodologyBuilder";
import { routes } from "../routes";
import { updateRiskRatingsLowRange } from "./update-risk-ratings-low-range";

function CreateRiskMethodology() {
  const history = useHistory();
  const mainStore = useMainStore();

  const [name, setName] = useState<string>(DEFAULTS.name);

  const [highRange, setHighRange] = useState<number>(DEFAULTS.highRange);
  const [lowRange, setLowRange] = useState<number>(DEFAULTS.lowRange);

  const [saveable, setSaveable] = useState(true);
  const [saving, setSaving] = useState(false);
  const [isWeightingEnabled, setIsWeightingEnabled] = useState<boolean>(false);
  const [isScoringEnabled, setIsScoringEnabled] = useState(true);

  const [overallRiskRatings, setOverallRiskRatings] = useState<
    Array<RiskRating>
  >(DEFAULTS.overallRiskRatings);

  const [inherentRiskRatings, setInherentRiskRatings] = useState<
    Array<RiskRating>
  >(DEFAULTS.inherentRiskRatings);

  const [controlRiskRatings, setControlRiskRatings] = useState<
    Array<RiskRating>
  >(DEFAULTS.controlRiskRatings);

  const [residualRisks, setResidualRisks] = React.useState<Array<ResidualRisk>>(
    DEFAULTS.residualRisks,
  );

  const [residualRiskMatrices, setResidualRiskMatrices] = useState<
    Array<ResidualRiskMatrix>
  >(DEFAULTS.residualRiskMatrices);

  const [errorText, setErrorText] = useState<string>("");

  useEffect(() => {
    setOverallRiskRatings(
      updateRiskRatingsLowRange(overallRiskRatings, lowRange),
    );

    setInherentRiskRatings(
      updateRiskRatingsLowRange(inherentRiskRatings, lowRange),
    );

    setControlRiskRatings(
      updateRiskRatingsLowRange(controlRiskRatings, lowRange),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lowRange]);

  const { workspaceID } = mainStore.context;

  useEffect(() => {
    if (errorText.length === 0) {
      return;
    }

    mainStore.toast.setErrorText(errorText);
  }, [errorText]);

  const unblock = usePreventUnsavedChanges(
    [
      !isEqual(DEFAULTS, {
        name,
        lowRange,
        highRange,
        overallRiskRatings,
        inherentRiskRatings,
        controlRiskRatings,
        residualRisks,
        residualRiskMatrices,
      }),
    ],
    [
      name,
      highRange,
      overallRiskRatings,
      inherentRiskRatings,
      controlRiskRatings,
      residualRisks,
      residualRiskMatrices,
    ],
  );

  const saveRiskMethodology = async () => {
    try {
      if (!saveable || !workspaceID) {
        return;
      }
      setSaving(true);

      const risk_ratings_attributes = [
        ...overallRiskRatings.map((r) => ({ ...r, risk_type: "overall" })),
      ];
      if (isScoringEnabled) {
        risk_ratings_attributes.push(
          ...controlRiskRatings.map((r) => ({ ...r, risk_type: "control" })),
        );
        risk_ratings_attributes.push(
          ...inherentRiskRatings.map((r) => ({ ...r, risk_type: "inherent" })),
        );
      }

      const isNameEmpty = name.length === 0;
      if (isNameEmpty) {
        setErrorText("Name is required");
        setSaving(false);
        return;
      }
      const riskMethodology = {
        risk_methodology: {
          name,
          weighting_enabled: isWeightingEnabled,
          higher_range: highRange,
          lower_range: lowRange,
          risk_ratings_attributes,
          residual_risks_attributes: isScoringEnabled
            ? residualRisks.filter((r) => r.text.length > 0)
            : [],
        },
        residual_risk_matrices: isScoringEnabled ? residualRiskMatrices : [],
      };
      const savedRiskMethodology = await RiskMethodologyAPI.create(
        workspaceID,
        riskMethodology,
      );
      mainStore.riskMethodologies.set([
        ...(mainStore.riskMethodologies.list || []),
        savedRiskMethodology,
      ]);
      unblock();
      history.push(
        generatePath(routes.METHODOLOGY_PATH, {
          workspace_id: Number(workspaceID),
        }),
      );
    } catch (err) {
      mainStore.toast.setErrorFromResponse(err);
    } finally {
      setSaving(false);
    }
  };

  return (
    <FlexDashboardContent data-testid="create-risk-methodology-content">
      <DashboardHeader
        title="Add New Scoring Template"
        onBackClick={() =>
          history.push(
            generatePath(routes.METHODOLOGY_PATH, {
              workspace_id: Number(workspaceID),
            }),
          )
        }
        LeftActionBar={<ViewModuleUsers />}
        dataTestID="create-risk-methodology-header"
      />
      <FlexDashboardContentWrapper
        data-testid="create-risk-methodology-content-wrapper"
        ModuleHeaderContent={
          <>
            <div />
            <Button
              style={{ margin: "5px 0" }}
              label={
                saving ? "Saving Scoring Template" : "Save Scoring Template"
              }
              onClick={saveRiskMethodology}
              data-testid="save-button"
              disabled={!saveable || saving}
            />
          </>
        }
      >
        <RiskMethodologyBuilder
          isWeightingEnabled={isWeightingEnabled}
          onChangeWeightingEnabled={setIsWeightingEnabled}
          isScoringEnabled={isScoringEnabled}
          onScoringEnabledChange={setIsScoringEnabled}
          name={name}
          onNameChange={(e) => setName(e)}
          overallRiskRatings={overallRiskRatings}
          inherentRiskRatings={inherentRiskRatings}
          updateOverallRiskRatings={setOverallRiskRatings}
          updateInherentRiskRatings={setInherentRiskRatings}
          controlRiskRatings={controlRiskRatings}
          updateControlRiskRatings={setControlRiskRatings}
          lowRange={lowRange}
          highRange={highRange}
          setHighRange={setHighRange}
          setLowRange={setLowRange}
          nameError={errorText}
          residualRisks={residualRisks}
          updateResidualRisks={setResidualRisks}
          residualRiskMatrices={residualRiskMatrices}
          updateResidualRiskMatrices={setResidualRiskMatrices}
          setSaveable={setSaveable}
        />
      </FlexDashboardContentWrapper>
    </FlexDashboardContent>
  );
}

export default observer(CreateRiskMethodology);
