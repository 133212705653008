import { isEqual } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { CREATE_RISK_METHODOLOGY_DEFAULTS as DEFAULTS } from "@/features/risk-assessment";
import FlexDashboardContent from "@/features/risk-assessment/components/FlexDashboardContent/FlexDashboardContent";
import type {
  ResidualRisk,
  ResidualRiskMatrix,
  RiskRating,
} from "@/features/risk-assessment/types/risk-methodology";
import { usePreventUnsavedChanges } from "@/hooks/usePreventUnsavedChanges";

import { RiskMethodologyAPI } from "../../../api/legacy/risk-assessment/RiskMethodologiesApi";
import ViewModuleUsers from "../../../components/dashboard/ViewModuleUsers";
import { Button } from "../../../components/Elements";
import Loading from "../../../components/Loading";
import DashboardHeader from "../../../components/shared/DashboardHeader";
import { useMainStore } from "../../../contexts/Store";
import FlexDashboardContentWrapper from "../components/FlexDashboardContentWrapper/FlexDashboardContentWrapper";
import RiskMethodologyBuilder from "../components/RiskMethodology/RiskMethodologyBuilder/RiskMethodologyBuilder";
import { routes } from "../routes";
import { updateRiskRatingsLowRange } from "./update-risk-ratings-low-range";

function EditRiskMethodology() {
  const history = useHistory();
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  const [name, setName] = useState("");
  const [highRange, setHighRange] = useState(4);
  const [lowRange, setLowRange] = useState<number>(DEFAULTS.lowRange);
  const [isWeightingEnabled, setIsWeightingEnabled] = useState(false);
  const [isScoringEnabled, setIsScoringEnabled] = useState(false);
  const [overallRiskRatings, setOverallRiskRatings] = useState<
    Array<RiskRating>
  >([]);
  const [inherentRiskRatings, setInherentRiskRatings] = useState<
    Array<RiskRating>
  >([]);
  const [controlRiskRatings, setControlRiskRatings] = useState<
    Array<RiskRating>
  >([]);
  const [residualRisks, setResidualRisks] = useState<Array<ResidualRisk>>([]);
  const [residualRiskMatrices, setResidualRiskMatrices] = useState<
    Array<ResidualRiskMatrix>
  >([]);
  const [loading, setLoading] = useState(true);
  const [errorText, setErrorText] = useState("");
  const [saveable, setSaveable] = useState(true);

  const originalRiskMethodologyRef = useRef(null);

  const { riskMethodologyId } = useParams<{ riskMethodologyId: string }>();

  useEffect(() => {
    setOverallRiskRatings(
      updateRiskRatingsLowRange(overallRiskRatings, lowRange),
    );

    setInherentRiskRatings(
      updateRiskRatingsLowRange(inherentRiskRatings, lowRange),
    );

    setControlRiskRatings(
      updateRiskRatingsLowRange(controlRiskRatings, lowRange),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lowRange]);

  useEffect(() => {
    loadRiskMethodology();
  }, []);

  useEffect(() => {
    if (errorText.length === 0) {
      return;
    }

    mainStore.toast.setErrorText(errorText);
  }, [errorText]);

  const unblock = usePreventUnsavedChanges(
    [
      !isEqual(originalRiskMethodologyRef.current, {
        name,
        isWeightingEnabled,
        highRange,
        overallRiskRatings,
        inherentRiskRatings,
        controlRiskRatings,
        residualRisks,
        residualRiskMatrices,
      }),
    ],
    [
      name,
      isWeightingEnabled,
      highRange,
      overallRiskRatings,
      inherentRiskRatings,
      controlRiskRatings,
      residualRisks,
      residualRiskMatrices,
    ],
  );

  const loadRiskMethodology = async () => {
    const { risk_methodology: riskMethodology } = await RiskMethodologyAPI.get(
      workspaceID!,
      parseInt(riskMethodologyId),
    );
    setName(riskMethodology.name);
    setOverallRiskRatings(riskMethodology.overall_risk_ratings);
    setInherentRiskRatings(riskMethodology.inherent_risk_ratings);
    setControlRiskRatings(riskMethodology.control_risk_ratings);
    setLowRange(riskMethodology.lower_range);
    setHighRange(riskMethodology.higher_range);
    setResidualRisks(riskMethodology.residual_risks);
    setResidualRiskMatrices(riskMethodology.residual_risk_matrices);
    setIsWeightingEnabled(riskMethodology.weighting_enabled);
    if (
      riskMethodology.inherent_risk_ratings.length > 0 ||
      riskMethodology.control_risk_ratings.length > 0
    ) {
      setIsScoringEnabled(true);
    }
    // @ts-expect-error TS(2322) FIXME: Type '{ name: string; highRange: number; inherentR... Remove this comment to see the full error message
    originalRiskMethodologyRef.current = {
      name: riskMethodology.name,
      isWeightingEnabled: riskMethodology.weighting_enabled,
      highRange: riskMethodology.higher_range,
      overallRiskRatings: riskMethodology.overall_risk_ratings,
      inherentRiskRatings: riskMethodology.inherent_risk_ratings,
      controlRiskRatings: riskMethodology.control_risk_ratings,
      residualRisks: riskMethodology.residual_risks,
      residualRiskMatrices: riskMethodology.residual_risk_matrices.map(
        ({ id, ...risk }) => risk,
      ),
    };
    setLoading(false);

    return originalRiskMethodologyRef.current;
  };

  const saveRiskMethodology = async () => {
    try {
      if (!saveable) {
        return;
      }

      const risk_ratings_attributes = [
        ...overallRiskRatings.map((risk) => ({
          ...risk,
          risk_type: "overall",
        })),
        ...controlRiskRatings.map((risk) => ({
          ...risk,
          risk_type: "control",
        })),
        ...inherentRiskRatings.map((risk) => ({
          ...risk,
          risk_type: "inherent",
        })),
      ];

      if (name.length === 0) {
        setErrorText("Name is required");
        return;
      }

      const riskMethodology = {
        risk_methodology: {
          name,
          weighting_enabled: isWeightingEnabled,
          higher_range: highRange,
          lower_range: lowRange,
          risk_ratings_attributes,
          residual_risks_attributes: residualRisks.filter(
            ({ text }) => text.length > 0,
          ),
        },
        residual_risk_matrices: isScoringEnabled ? residualRiskMatrices : [],
      };

      const updatedRiskMethodology = await RiskMethodologyAPI.update(
        workspaceID!,
        parseInt(riskMethodologyId),
        riskMethodology,
      );

      mainStore.riskMethodologies.replaceWith(updatedRiskMethodology);

      unblock();
      history.push(
        generatePath(routes.METHODOLOGY_PATH, {
          workspace_id: Number(workspaceID),
        }),
      );
    } catch (err) {
      mainStore.toast.setErrorFromResponse(err);
    }
  };

  function onScoringEnabledChange(isScoringEnabledValue: boolean) {
    if (isScoringEnabledValue && !residualRiskMatrices.length) {
      setInherentRiskRatings(DEFAULTS.inherentRiskRatings);
      setControlRiskRatings(DEFAULTS.controlRiskRatings);
      setResidualRisks(DEFAULTS.residualRisks);
      setResidualRiskMatrices(DEFAULTS.residualRiskMatrices);
    }

    setIsScoringEnabled(isScoringEnabledValue);
  }

  if (loading) {
    return <Loading loadingLayout="small-table" showTableHeader={false} />;
  }

  return (
    <FlexDashboardContent data-testid="create-risk-methodology-content">
      <DashboardHeader
        title="Update Scoring Template"
        onBackClick={() =>
          history.push(
            generatePath(routes.METHODOLOGY_PATH, {
              workspace_id: Number(workspaceID),
            }),
          )
        }
        LeftActionBar={<ViewModuleUsers />}
        dataTestID="create-risk-methodology-header"
      />
      <FlexDashboardContentWrapper
        data-testid="create-risk-methodology-content-wrapper"
        ModuleHeaderContent={
          <>
            <div />
            <Button
              style={{ margin: "5px 0" }}
              label="Save Scoring Template"
              onClick={saveRiskMethodology}
              data-testid="save-button"
            />
          </>
        }
      >
        <RiskMethodologyBuilder
          isWeightingEnabled={isWeightingEnabled}
          onChangeWeightingEnabled={setIsWeightingEnabled}
          isScoringEnabled={isScoringEnabled}
          onScoringEnabledChange={onScoringEnabledChange}
          name={name}
          onNameChange={(e) => setName(e)}
          overallRiskRatings={overallRiskRatings}
          updateOverallRiskRatings={setOverallRiskRatings}
          inherentRiskRatings={inherentRiskRatings}
          updateInherentRiskRatings={setInherentRiskRatings}
          controlRiskRatings={controlRiskRatings}
          updateControlRiskRatings={setControlRiskRatings}
          lowRange={lowRange}
          highRange={highRange}
          setHighRange={setHighRange}
          setLowRange={setLowRange}
          nameError={errorText}
          residualRisks={residualRisks}
          updateResidualRisks={setResidualRisks}
          residualRiskMatrices={residualRiskMatrices}
          updateResidualRiskMatrices={setResidualRiskMatrices}
          setSaveable={setSaveable}
        />
      </FlexDashboardContentWrapper>
    </FlexDashboardContent>
  );
}

export default observer(EditRiskMethodology);
