import type { RecordTypePath } from "@themis/api/gen/models/recordTypePath";
import type { ColDef, ColDefField } from "ag-grid-community";
import type { CustomCellRendererProps } from "ag-grid-react";
import { get } from "lodash";

import { CommentsCell } from "./comments-cell";

export function getCommentsCellColumnDef<
  T extends object,
  Field extends ColDefField<T>,
>(
  recordType: RecordTypePath,
  field: Field,
): ColDef<T> & {
  order: number;
} {
  return {
    order: 0,
    field,
    valueGetter: ({ data }) => {
      if (!data) {
        return null;
      }

      return get(data, field);
    },
    colId: field,
    headerName: "",
    headerComponent: null,
    minWidth: 60,
    width: 60,
    flex: 0,
    sortable: false,
    resizable: false,
    headerClass: "!tw-p-0",
    cellClass: "!tw-p-0",
    cellStyle: () => ({ justifyContent: "center" }),
    cellRenderer: ({ data, value }: CustomCellRendererProps) => {
      if (!data) {
        return null;
      }

      return (
        <CommentsCell
          commentsCount={value}
          recordId={data.id}
          recordType={recordType}
        />
      );
    },
  };
}
