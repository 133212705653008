import type { FieldConfiguration } from "@/api";

import type { FilterFieldData } from "../../../hooks/use-filter-sort/types";

export function getFiltersFieldData<T>(
  fields: FieldConfiguration[] | undefined,
) {
  if (!fields) {
    return;
  }

  return fields.reduce<FilterFieldData<T>>((acc, field) => {
    if (field.field_type === "text") {
      return {
        ...acc,
        [field.path]: {
          displayName: field.display_name,
          type: "string",
        },
      };
    }

    if (!field.options) {
      return acc;
    }

    return {
      ...acc,
      [field.path]: {
        displayName: field.display_name,
        options: field.options.map((option) => ({
          value: option.value,
          key: option.key,
        })),
        type: "string",
      },
    };
  }, {});
}
