import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  ScrollArea,
} from "@themis/ui";
import { FormControlLabel } from "@themis/ui-library/components/form/form-control-label/form-control-label";
import { FormGroup } from "@themis/ui-library/components/form/form-group/form-group";
import { Checkbox } from "@themis/ui-library/components/inputs/checkbox/checkbox";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { useState } from "react";

import type { FilterData } from "../../../hooks/use-filter-sort/types";
import { TextFieldFilterSelect } from "./text-field-filter-select/text-field-filter-select";

interface FilterSelectAccordianProps {
  filtersFieldData: Partial<Record<string, FilterData>>;
  onSelect: (filterKey: string, optionKey: string) => void;
  selected: Record<string, string[]>;
}

export function FilterSelectAccordion({
  filtersFieldData,
  onSelect,
  selected,
}: FilterSelectAccordianProps) {
  const [textFilterOptions, setTextFilterOptions] = useState<
    Record<string, Array<string>>
  >(getFilterOptions(filtersFieldData, selected));

  const handleSelectTextFilter = (filterKey: string, newValue: string) => {
    onSelect(filterKey, newValue);

    if (!textFilterOptions[filterKey]?.includes(newValue)) {
      setTextFilterOptions((prev) => {
        return {
          ...prev,
          [filterKey]: [...(prev[filterKey] ?? []), newValue],
        };
      });
    }
  };

  return (
    <ScrollArea viewportClassname="tw-max-h-96">
      <Accordion type="multiple" defaultValue={Object.keys(selected)}>
        {Object.keys(filtersFieldData).map((filterKey) => {
          const { displayName, options } = filtersFieldData[filterKey] || {};

          return (
            <AccordionItem key={filterKey} value={filterKey}>
              <AccordionTrigger className="tw-px-4 tw-py-3 tw-font-medium tw-text-neutral-500">
                {displayName}
              </AccordionTrigger>

              <AccordionContent className="tw-bg-primary-25">
                {options ? (
                  <Stack component={FormGroup}>
                    {options.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        slotProps={{
                          typography: {
                            color: "textPrimary",
                          },
                        }}
                        labelPlacement="end"
                        control={
                          <Checkbox
                            size="small"
                            checked={
                              selected[filterKey]?.includes(option.key) ?? false
                            }
                            onChange={() => onSelect(filterKey, option.key)}
                          />
                        }
                        label={option.value}
                      />
                    ))}
                  </Stack>
                ) : (
                  <TextFieldFilterSelect
                    displayName={displayName}
                    selectedFilters={selected[filterKey] ?? []}
                    filters={textFilterOptions[filterKey] ?? []}
                    onSelectFilter={(newValue) =>
                      handleSelectTextFilter(filterKey, newValue)
                    }
                  />
                )}
              </AccordionContent>
            </AccordionItem>
          );
        })}
      </Accordion>
    </ScrollArea>
  );
}

function getFilterOptions(
  filtersFieldData: Partial<Record<string, FilterData>>,
  selected: Record<string, string[]>,
) {
  const optionsFilterKeys = Object.keys(filtersFieldData).filter(
    (key) => filtersFieldData[key]?.options,
  );

  return Object.entries(selected)
    .filter(([key]) => !optionsFilterKeys.includes(key))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
}
