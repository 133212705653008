// eslint-disable-next-line @nx/enforce-module-boundaries
import { TableComponent } from "@themis/ui";
import type {
  GridState as AgGridState,
  ColDef,
  ColumnState,
  DomLayoutType,
  GetRowIdFunc,
  IDatasource,
  RowModelType,
  SelectionChangedEvent,
  StateUpdatedEvent,
} from "ag-grid-community";
import { AllCommunityModule } from "ag-grid-community";
import type { AgGridReactProps } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react";
import type {
  ChangeEvent,
  ComponentProps,
  MouseEvent,
  ReactElement,
} from "react";
import { memo, useCallback, useEffect, useRef } from "react";

import { styled } from "../../styles";
import { TablePagination } from "../navigation/pagination/table-pagination/table-pagination";
import { defaultColumnDefParams } from "./column-defs/default-column-def-params";
import { selectionColumnDefParams } from "./column-defs/selection-column-def-params";
import { LoadingOverlay } from "./loading-overlay/loading-overlay";
import { computeGridState } from "./state/compute-grid-state";
import type { DataGridState, SortDirection } from "./state/data-grid-state";
import { TableWrapper } from "./table-wrapper";
import { useDataGridTheme } from "./use-data-grid-theme";

const Wrapper = styled("div")({
  width: "100%",
  maxHeight: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
});

export interface DataGridProps<T extends object>
  extends ComponentProps<typeof Wrapper> {
  id?: string;
  columns?: ColDef<T>[];
  dataGridState: DataGridState;
  datasource?: IDatasource;
  domLayout?: DomLayoutType;
  getRowId: GetRowIdFunc<T>;
  initialState?: AgGridState;
  isLoading?: boolean;
  pagination?: boolean;
  infiniteInitialRowCount?: number;
  maxConcurrentDatasourceRequests?: number;
  rowHeight?: number;
  rowModelType?: RowModelType;
  rows?: T[];
  selection?: "single" | "multiple";
  total: number;
  width?: string | number;
  onDataGridStateChange?: (state: DataGridState) => void;
  onGridReady?: AgGridReactProps["onGridReady"];
  onSelectionChanged?: (rows: T[]) => void;
}

function DataGrid<T extends object>({
  columns,
  datasource,
  dataGridState,
  domLayout = "autoHeight",
  getRowId,
  id,
  infiniteInitialRowCount,
  initialState,
  isLoading,
  maxConcurrentDatasourceRequests,
  pagination,
  rows,
  rowHeight = 40,
  rowModelType = "clientSide",
  selection,
  total,
  width,
  onDataGridStateChange,
  onGridReady,
  onSelectionChanged,
  ...otherProps
}: DataGridProps<T>): ReactElement {
  const theme = useDataGridTheme();
  const gridRef = useRef<AgGridReact>(null);
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const isReadyRef = useRef(false);

  const updateReady = useCallback(() => {
    if (isReadyRef.current) {
      return;
    }

    isReadyRef.current = true;
    if (tableWrapperRef.current) {
      tableWrapperRef.current.style.visibility = "visible";
    }
  }, []);

  useEffect(() => {
    // update columnDefs when columns change
    gridRef.current?.api?.setGridOption("columnDefs", columns);

    // none of the columns are flexed so we can show the grid immediately
    if (!columns?.some((column) => column.flex != null)) {
      updateReady();
    }
  }, [columns]);

  useEffect(() => {
    if (selection) {
      const selectedRows = gridRef.current?.api?.getSelectedRows() ?? [];
      const selectedRowsCount = selectedRows.length;

      if (selectedRowsCount) {
        // when data changes, we need to deselect all rows
        gridRef.current?.api?.deselectAll?.();
      }
    }
  }, [datasource, rows, selection]);

  const rowSelectionEnabled = !!selection;
  const hasRows = !!rows?.length || !!total;

  // sync the ag grid state with the data grid state
  useEffect(() => {
    const gridApi = gridRef.current?.api;

    if (!gridApi) {
      return;
    }

    if (dataGridState.pageIndex != null) {
      gridApi.paginationGoToPage(dataGridState.pageIndex);
    }
    if (dataGridState.pageSize != null) {
      gridApi.setGridOption("paginationPageSize", dataGridState.pageSize);
    }

    if (dataGridState.sorts) {
      const state = Object.entries(dataGridState.sorts).map(([colId, sort]) => {
        const columnState: ColumnState = {
          colId,
          sort: sort as SortDirection,
        };

        return columnState;
      });
      gridApi.applyColumnState({
        state,
        defaultState: {
          sort: null,
        },
      });
    }
  }, [dataGridState]);

  const handleGridSizeChanged = useCallback(() => {
    // we wait for first sizing to prevent columns size flickering
    updateReady();
  }, [updateReady]);

  const handlePageChange = useCallback(
    (_event: MouseEvent | null, pageIndex: number) => {
      gridRef.current?.api?.paginationGoToPage(pageIndex);
      onDataGridStateChange?.(computeGridState(dataGridState, { pageIndex }));
    },
    [dataGridState, onDataGridStateChange],
  );

  const handleRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const pageSize = Number(event.target.value);
      gridRef.current?.api?.setGridOption("paginationPageSize", pageSize);
      onDataGridStateChange?.(computeGridState(dataGridState, { pageSize }));
    },
    [dataGridState, onDataGridStateChange],
  );

  const handleSelectionChanged = useCallback(
    (event: SelectionChangedEvent<T, unknown>) => {
      onSelectionChanged?.(event.api.getSelectedRows());
    },
    [onSelectionChanged],
  );

  const handleStateUpdated = useCallback(
    (event: StateUpdatedEvent) => {
      const stateUpdate: Partial<DataGridState> = {};
      if (event.state.pagination) {
        stateUpdate.pageIndex = event.state.pagination.page;
        stateUpdate.pageSize = event.state.pagination.pageSize;
      }
      stateUpdate.sorts = (event.state.sort?.sortModel ?? []).reduce(
        (acc: Record<string, SortDirection>, sortModelItem) => {
          acc[sortModelItem.colId] = sortModelItem.sort;
          return acc;
        },
        {},
      );
      const newState = computeGridState(dataGridState, stateUpdate);
      onDataGridStateChange?.(newState);
    },
    [dataGridState, onDataGridStateChange],
  );

  const paginationProps: AgGridReactProps = pagination
    ? {
        pagination,
        paginationPageSize: dataGridState.pageSize,
        suppressPaginationPanel: true,
      }
    : {};
  const selectionProps: AgGridReactProps = selection
    ? {
        rowSelection: {
          headerCheckbox: false,
          mode: selection === "single" ? "singleRow" : "multiRow",
        },
        selectionColumnDef: selectionColumnDefParams,
        onSelectionChanged: handleSelectionChanged,
      }
    : {};

  const modules = [AllCommunityModule];

  return (
    <Wrapper {...otherProps} style={{ width }}>
      <TableWrapper
        ref={tableWrapperRef}
        id={id}
        style={{
          width,
          visibility: isReadyRef.current ? "visible" : "hidden",
        }}
        role="table"
        hasRows={hasRows}
        rowSelectionEnabled={rowSelectionEnabled}
      >
        <AgGridReact
          ref={gridRef}
          accentedSort
          animateRows={false}
          // todo: remove when all renderers are migrated
          components={TableComponent}
          columnDefs={columns}
          context={{ gridRef }}
          datasource={datasource}
          defaultColDef={defaultColumnDefParams}
          domLayout={domLayout}
          getRowId={getRowId}
          infiniteInitialRowCount={infiniteInitialRowCount}
          initialState={initialState}
          maxConcurrentDatasourceRequests={maxConcurrentDatasourceRequests}
          modules={modules}
          rowData={rows}
          rowHeight={rowHeight}
          rowModelType={rowModelType}
          suppressCellFocus
          suppressMultiSort
          suppressNoRowsOverlay
          suppressRowVirtualisation
          suppressScrollOnNewData={false}
          theme={theme}
          onGridReady={onGridReady}
          onGridSizeChanged={handleGridSizeChanged}
          onStateUpdated={handleStateUpdated}
          {...paginationProps}
          {...selectionProps}
        />
        {isLoading && <LoadingOverlay />}
      </TableWrapper>

      {pagination && (
        <TablePagination
          count={total}
          page={dataGridState?.pageIndex ?? 0}
          rowsPerPage={dataGridState?.pageSize ?? 10}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </Wrapper>
  );
}

const MemoizedDataGrid = memo(DataGrid) as typeof DataGrid;

export { MemoizedDataGrid as DataGrid };
