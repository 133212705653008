import type { FieldConfigurationReferenceType } from "@themis/api/gen/models/fieldConfiguration";
import type { ComponentType } from "react";

import type { BaseDynamicValueProps } from "../base-dynamic-value";
import { DepartmentValue } from "./department-value/department-value";
import { DocumentValue } from "./document-value/document-value";
import { PolicyValue } from "./policy-value/policy-value";
import { ReviewValue } from "./review-value/review-value";
import { ThemisRecordValue } from "./themis-record-value/themis-record-value";
import { UserValue } from "./user-value/user-value";

const referenceCells = {
  Account: null,
  Attachment: null,
  ThemisRecord: ThemisRecordValue,
  Contact: null,
  Contract: null,
  Department: DepartmentValue,
  Issue: null,
  Policy: PolicyValue,
  Questionnaire: null,
  RecordLink: null,
  RecordVersion: null,
  Review: ReviewValue,
  Task: null,
  ThemisDocument: DocumentValue,
  User: UserValue,
  VendorChecklist: null,
  MonitoringAsset: null,
  MonitoringFinding: null,
  RecordReference: ThemisRecordValue,
} satisfies Record<
  FieldConfigurationReferenceType,
  ComponentType<BaseDynamicValueProps> | null
>;

export const ReferenceValue = ({
  value,
  configuration,
  record,
}: BaseDynamicValueProps) => {
  if (!configuration.reference_type) {
    return null;
  }

  const Component = referenceCells[configuration.reference_type];

  if (Component) {
    return (
      <Component value={value} configuration={configuration} record={record} />
    );
  }
};
