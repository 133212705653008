import type { Comment } from "@themis/api/gen/models/comment";
import { CommentsList } from "@themis/shared/components/comments/comments-list/comments-list";
import { CreateComment } from "@themis/shared/components/comments/create-comment/create-comment";
import { RecordCommentsProvider } from "@themis/shared/contexts/record-comments-context/record-comments-context";
import { IconButton } from "@themis/ui";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { keyBy } from "lodash";
import { PiXBold } from "react-icons/pi";
import { useIntl } from "react-intl";

import type { ListCommentsPathParams } from "@/api";
import { useComments } from "@/api/queries/comments/use-comments";
import { useWorkspace } from "@/api/queries/workspaces/use-workspace";
import SlideMenu from "@/components/slideMenu/SlideMenu";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";
import { useSearchParams } from "@/hooks/useSearchParams";

import type { CommentTab } from "../table/shared/comments/CommentsSlideMenu/types";
import { CommentsTabs } from "./comments-tabs/comments-tabs";
import { useCommentsRouteParams } from "./use-comments-route-params/use-comments-route-params";
import { useUserMentionsUsers } from "./user-mention/use-user-mentions-users/use-user-mentions-users";

interface CommentsSidebarProps {
  detailsPagePath: string;
  recordId: number;
  recordName: string | undefined;
  recordType: ListCommentsPathParams["record_type"];
}

export function CommentsSidebar({
  detailsPagePath,
  recordId,
  recordName,
  recordType,
}: CommentsSidebarProps) {
  const { formatMessage } = useIntl();
  const { isOpen, close: closeComments } = useCommentsRouteParams();
  const { data: comments = [] } = useComments(
    {
      recordType,
      recordId,
    },
    { enabled: isOpen },
  );

  const workspaceId = useRouteWorkspaceId();
  const { data: workspace } = useWorkspace(workspaceId);

  const [searchParams] = useSearchParams<{
    commentsTab?: CommentTab;
  }>();

  const privateComments = comments.filter((comment) => comment.private);
  const collaborativeComments = comments.filter((comment) => !comment.private);

  const mentionsUsers = useUserMentionsUsers();
  const mentionUsersMap = keyBy(mentionsUsers, (user) => user.id);

  const filterFn = (comment: Comment) => {
    // internal shows all comments
    if (workspace?.is_internal) {
      return true;
    }

    if (searchParams.commentsTab === "internal") {
      return comment.private;
    }

    return !comment.private;
  };

  const getCommentDetailUrl = (): string => {
    const url = new URL(window.location.href);

    url.pathname = detailsPagePath;

    url.search = new URLSearchParams({
      showCommentsForRecord: recordId.toString(),
      commentsTab: searchParams.commentsTab ?? "collaborative",
    }).toString();

    return url.toString();
  };

  return (
    <RecordCommentsProvider commentDetailUrl={getCommentDetailUrl()}>
      <SlideMenu open={isOpen} closeSlideMenu={closeComments} singleSlideMenu>
        <div className="tw-flex tw-h-full tw-flex-col">
          <div className="tw-flex tw-h-14 tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-neutral-100 tw-px-5">
            <div className="tw-flex tw-items-center tw-gap-2">
              <label className="tw-text-base tw-font-bold tw-text-primary-600">
                {formatMessage({
                  defaultMessage: "Comments",
                })}
              </label>
              <span className="tw-rounded-md tw-bg-primary-50 tw-px-2 tw-py-0.5 tw-text-primary-400">
                {recordName}
              </span>
            </div>
            <IconButton
              Icon={PiXBold}
              color="transparent"
              onClick={closeComments}
            />
          </div>

          <CommentsTabs
            collaborativeCommentsLength={collaborativeComments.length}
            privateCommentsLength={privateComments.length}
          />

          <Stack
            className="tw-relative tw-flex-1 tw-overflow-auto"
            py={2}
            sx={(theme) => ({
              scrollPadding: theme.spacing(1),
            })}
          >
            <CommentsList
              filterFn={filterFn}
              recordId={recordId}
              recordName={recordName ?? ""}
              recordTypePath={recordType}
              workspaceId={workspaceId}
            />
          </Stack>

          <div className="tw-border-x-0 tw-border-b-0 tw-border-t tw-border-solid tw-border-neutral-100 tw-px-5 tw-py-2">
            <CreateComment
              internal={searchParams.commentsTab === "internal"}
              inputSize="medium"
              placeholder={formatMessage({
                defaultMessage: "Add a comment. Use @ to mention a user.",
              })}
              recordId={recordId}
              recordTypePath={recordType}
              usersMap={mentionUsersMap}
            />
          </div>
        </div>
      </SlideMenu>
    </RecordCommentsProvider>
  );
}
