import { Route, Switch } from "react-router-dom";

import RecordRedirectPage from "./record-redirect-page/record-redirect-page";
import { routes } from "./routes";

export default function RecordRedirectRoutes() {
  return (
    <Switch>
      <Route exact path={routes.recordRedirect}>
        <RecordRedirectPage />
      </Route>
    </Switch>
  );
}
