import { faker } from "@faker-js/faker";

import type {
  CreateAttestation201,
  CreateAttestationMutationRequest,
  CreateAttestationMutationResponse,
  CreateAttestationPathParams,
} from "../models/CreateAttestation";
import { createAttestation } from "./createAttestation";
import { createRecordTypes } from "./createRecordTypes";

export function createCreateAttestationPathParams(): NonNullable<CreateAttestationPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description Created
 */
export function createCreateAttestation201(): NonNullable<CreateAttestation201> {
  return { data: createAttestation() };
}

export function createCreateAttestationMutationRequest(): NonNullable<CreateAttestationMutationRequest> {
  return {
    attestation: {
      workspace_id: faker.number.int(),
      name: faker.string.alpha(),
      description: faker.string.alpha(),
      message: faker.string.alpha(),
      owner_id: faker.number.int(),
      due_date: faker.string.alpha(),
      attestables: faker.helpers.arrayElements([
        { id: faker.number.int(), record_type: createRecordTypes() },
      ]) as any,
    },
  };
}

/**
 * @description Created
 */
export function createCreateAttestationMutationResponse(): NonNullable<CreateAttestationMutationResponse> {
  return { data: createAttestation() };
}
