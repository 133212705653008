import { faker } from "@faker-js/faker";

import type {
  Update200,
  UpdateMutationRequest,
  UpdateMutationResponse,
  UpdatePathParams,
} from "../models/Update";
import { createAttestation } from "./createAttestation";
import { createRecordTypes } from "./createRecordTypes";

export function createUpdatePathParams(): NonNullable<UpdatePathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdate200(): NonNullable<Update200> {
  return { data: createAttestation() };
}

export function createUpdateMutationRequest(): NonNullable<UpdateMutationRequest> {
  return {
    attestation: {
      name: faker.string.alpha(),
      description: faker.string.alpha(),
      message: faker.string.alpha(),
      owner_id: faker.number.int(),
      due_date: faker.string.alpha(),
      attestables: faker.helpers.arrayElements([
        { id: faker.number.int(), record_type: createRecordTypes() },
      ]) as any,
    },
  };
}

/**
 * @description OK
 */
export function createUpdateMutationResponse(): NonNullable<UpdateMutationResponse> {
  return { data: createAttestation() };
}
