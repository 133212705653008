import { cn, Popover, PopoverContent, PopoverTrigger } from "@themis/ui";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import type { SortDirection } from "ag-grid-community";
import type { CustomHeaderProps } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import type { ReactElement } from "react";
import {
  PiArrowDown,
  PiArrowsDownUp,
  PiArrowUp,
  PiPushPin,
} from "react-icons/pi";

import type { FieldConfiguration } from "../../../api/gen/models/FieldConfiguration";
import type { RecordTypePath } from "../../../api/gen/models/RecordTypePath";
import { ColumnHeaderIcon } from "../column-header-icon/column-header-icon";
import { ColumnHeaderMenu } from "./column-header-menu";

interface ColumnHeaderMenuProps extends CustomHeaderProps {
  field: FieldConfiguration;
  recordType: RecordTypePath;
}

export function ColumnHeader({
  displayName = "",
  column,
  context,
  field,
  recordType,
}: ColumnHeaderMenuProps): ReactElement | null {
  const menuState = useOpen();

  const [sort, setSort] = useState<SortDirection | undefined>(column.getSort());

  const isPinned = column.isPinned();

  const enablePinning =
    !column.getColDef().lockPinned && !column.getColDef().lockPosition;

  useEffect(() => {
    const onSortChanged = () => {
      setSort(column.getSort());
    };
    column.addEventListener("sortChanged", onSortChanged);
    return () => {
      column.removeEventListener("sortChanged", onSortChanged);
    };
  }, [column]);

  const SortIcon = useMemo(() => {
    return {
      asc: PiArrowUp,
      desc: PiArrowDown,
      none: PiArrowsDownUp,
    }[sort ?? "none"];
  }, [sort]);

  if (!displayName) {
    return null;
  }

  return (
    <Popover open={menuState.isOpen} onOpenChange={menuState.onIsOpenChange}>
      <PopoverTrigger asChild>
        <button
          className={cn(
            "tw-group/header tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-0.5 tw-px-2 tw-transition-all hover:tw-bg-neutral-50",
            {
              "tw-bg-neutral-50 hover:tw-bg-neutral-50": menuState.isOpen,
            },
          )}
          type="button"
          onClick={menuState.toggle}
        >
          <ColumnHeaderIcon field={field} />
          <span className="tw-mr-auto tw-truncate tw-font-sans tw-text-sm tw-text-neutral-300">
            {displayName}
          </span>
          <div className="tw-flex tw-items-center">
            {enablePinning && isPinned && <PiPushPin size={16} />}
            <SortIcon />
          </div>
        </button>
      </PopoverTrigger>

      <PopoverContent align="end" onEscapeKeyDown={menuState.close}>
        <ColumnHeaderMenu
          context={context}
          column={column}
          field={field}
          recordType={recordType}
          onClose={menuState.close}
        />
      </PopoverContent>
    </Popover>
  );
}
