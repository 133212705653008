import type { Account } from "@themis/api/gen/models/account";
import type { ListAccountsQueryParams } from "@themis/api/gen/models/accountsController/listAccounts";
import { recordTypePath } from "@themis/api/gen/models/recordTypePath";
import { LinkButton } from "@themis/ui";
import { useServerDataGridBinding } from "@themis/ui-library/components/data-grid/binding/use-server-data-grid-binding";
import { DataGrid } from "@themis/ui-library/components/data-grid/data-grid";
import { useDataGridState } from "@themis/ui-library/components/data-grid/state/use-data-grid-state";
import classNames from "classnames";
import React from "react";
import { PiPlusBold } from "react-icons/pi";
import { useIntl } from "react-intl";
import { generatePath, useParams } from "react-router-dom";

import Loading from "@/components/Loading";
import useFilterSort from "@/hooks/use-filter-sort/use-filter-sort";

import { useAccounts } from "../../../../api/queries/accounts/accounts";
import { CommentsSidebar } from "../../../../components/comments-sidebar/comments-sidebar";
import { useCommentsRouteParams } from "../../../../components/comments-sidebar/use-comments-route-params/use-comments-route-params";
import { ErrorContainer } from "../../../../components/ErrorContainer";
import type { FilterFieldData } from "../../../../hooks/use-filter-sort/types";
import { useIsFeatureEnabled } from "../../../../hooks/useIsFeatureEnabled";
import { useSearchParams } from "../../../../hooks/useSearchParams";
import { FEATURE_FLAG_ID } from "../../../../stores/types/feature-flag-types";
import type {
  AccountListView,
  AccountSearchParams,
} from "../../pages/Accounts";
import { routes } from "../../routes";
import { useAccountsTableColumns } from "../../utils/use-accounts-table-columns";
import { AccountCard } from "../AccountCard";

function CardsContainer({ children }: { children: React.ReactNode }) {
  return (
    <div
      data-testid="cards-container"
      className="tw-flex tw-flex-wrap tw-items-center tw-gap-4 tw-self-stretch tw-py-2"
    >
      {children}
    </div>
  );
}

function CardPlaceholderContainer({ children }: { children: React.ReactNode }) {
  return (
    <section className="tw-flex tw-max-h-[180px] tw-min-h-[180px] tw-flex-wrap tw-items-center tw-justify-center tw-gap-8 tw-overflow-hidden">
      {children}
    </section>
  );
}

function CardPlaceholder() {
  return (
    <div className="tw-h-[180px] tw-w-[180px] tw-rounded-xl tw-bg-neutral-50" />
  );
}

function EmptyStateContainer({
  accountsListView,
  children,
}: {
  accountsListView: AccountListView;
  children: React.ReactNode;
}) {
  return (
    <div
      className={classNames(
        "tw-inline-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4",
        {
          "tw-pt-4": accountsListView === "grid",
          "tw-pt-48": accountsListView === "table",
        },
      )}
    >
      {children}
    </div>
  );
}

interface AccountListProps {
  filtersFieldData?: FilterFieldData<Account>;
  onChangeSelectedRowIds: (ids: number[]) => void;
}

export function AccountsList({
  filtersFieldData,
  onChangeSelectedRowIds,
}: AccountListProps) {
  const { formatMessage } = useIntl();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const [{ view }] = useSearchParams<AccountSearchParams>();
  const { listRequestQueryParams } = useFilterSort<Account>(
    filtersFieldData || {},
    recordTypePath.accounts,
  );
  const { dataGridState, updateDataGridState, dataGridQueryParams } =
    useDataGridState({
      filters: listRequestQueryParams.filters,
      sorts: listRequestQueryParams.sort_by,
    });
  const workspaceId = Number(workspace_id);

  const isPaginationEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_PAGINATION,
  );

  const queryParams: ListAccountsQueryParams | null = filtersFieldData
    ? {
        expand: "main_contact,parent",
        filters: listRequestQueryParams.filters,
        sort_by: dataGridState.sorts,
        offset: isPaginationEnabled ? dataGridQueryParams.offset : undefined,
        limit: isPaginationEnabled ? dataGridQueryParams.limit : undefined,
      }
    : null;

  const {
    data: accounts,
    isPending: isAccountsPending,
    isError: isAccountsError,
  } = useAccounts(Number(workspace_id), queryParams);

  const totalCount = accounts?.meta?.total_count || 0;

  const { selectedRecordId } = useCommentsRouteParams();
  const selectedAccount = accounts?.data.find(
    (account) => account.id === selectedRecordId,
  );

  const { columns, isColumnsError, isColumnsPending } = useAccountsTableColumns(
    {
      workspaceId,
    },
  );

  const handleSelectionChanged = (rows: Account[]) => {
    onChangeSelectedRowIds(rows.map((row) => row.id));
  };

  const accountsListView = view || "table";

  const { serverGridProps } = useServerDataGridBinding(accounts?.data, {
    dataGridState,
    pagination: isPaginationEnabled,
    total: totalCount,
    onDataGridStateChange: updateDataGridState,
  });

  if (isAccountsPending || isColumnsPending) {
    return <Loading loadingLayout={accountsListView} />;
  }

  if (isAccountsError || isColumnsError) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath("/workspaces/:workspace_id/home", {
            workspace_id,
          }),
        }}
      >
        {formatMessage({ defaultMessage: "Could not load accounts." })}
      </ErrorContainer>
    );
  }

  return (
    <>
      {selectedRecordId && (
        <CommentsSidebar
          detailsPagePath={generatePath(routes.accounts.detail.recordView, {
            workspace_id: workspaceId,
            accountId: selectedRecordId,
          })}
          recordId={selectedRecordId}
          recordName={selectedAccount?.name}
          recordType={recordTypePath.accounts}
        />
      )}

      {accountsListView === "grid" ? (
        <CardsContainer>
          {accounts.data.map((account: Account) => (
            <AccountCard
              key={account.id}
              linkTo={generatePath(routes.accounts.detail.recordView, {
                workspace_id,
                accountId: account.id,
              })}
              account={account}
            />
          ))}
        </CardsContainer>
      ) : (
        <DataGrid
          columns={columns}
          isLoading={isAccountsPending}
          selection="multiple"
          onSelectionChanged={handleSelectionChanged}
          {...serverGridProps}
        />
      )}

      {!accounts.data.length && (
        <>
          {accountsListView === "grid" && (
            <CardPlaceholderContainer>
              <CardPlaceholder />
              <CardPlaceholder />
              <CardPlaceholder />
              <CardPlaceholder />
              <CardPlaceholder />
            </CardPlaceholderContainer>
          )}
          <EmptyStateContainer accountsListView={accountsListView}>
            <h3 className="tw-text-base tw-font-semibold tw-text-neutral-500">
              {formatMessage({
                defaultMessage: "Add your first Account",
              })}
            </h3>
            <p className="tw-w-[370px] tw-text-center tw-text-sm tw-font-medium tw-text-neutral-300">
              {formatMessage({
                defaultMessage:
                  "To get started, add your first Account by clicking 'Add Account' and filling in the details.",
              })}
            </p>
            <LinkButton
              LeftIcon={PiPlusBold}
              to={generatePath(routes.accounts.detail.recordView, {
                workspace_id,
                accountId: "new",
              })}
            >
              {formatMessage({
                defaultMessage: "Add Account",
              })}
            </LinkButton>
          </EmptyStateContainer>
        </>
      )}
    </>
  );
}
