import { faker } from "@faker-js/faker";

import type { Attester } from "../models/Attester";

export function createAttester(
  data: NonNullable<Partial<Attester>> = {},
): NonNullable<Attester> {
  return {
    ...{
      user_id: faker.number.int(),
      attestation_status: faker.helpers.arrayElement<any>([
        "pending",
        "attested",
      ]),
      attested_at: faker.date.anytime().toISOString(),
      sent_at: faker.date.anytime().toISOString(),
      resent_count: faker.number.int(),
    },
    ...data,
  };
}
