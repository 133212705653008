import type { Account } from "@themis/api/gen/models/account";
import { recordTypePath } from "@themis/api/gen/models/recordTypePath";
import { AddNewRowLink } from "@themis/ui";
import { useClientDataGridBinding } from "@themis/ui-library/components/data-grid/binding/use-client-data-grid-binding";
import { DataGrid } from "@themis/ui-library/components/data-grid/data-grid";
import { useIntl } from "react-intl";
import { generatePath, useRouteMatch } from "react-router-dom";

import Loading from "@/components/Loading";

import { CommentsSidebar } from "../../../../components/comments-sidebar/comments-sidebar";
import { useCommentsRouteParams } from "../../../../components/comments-sidebar/use-comments-route-params/use-comments-route-params";
import { useRouteWorkspaceId } from "../../../../hooks/use-route-workspace-id";
import { routes } from "../../routes";
import { useAccountsTableColumns } from "../../utils/use-accounts-table-columns";

export function ChildAccounts({ childAccounts }: { childAccounts: Account[] }) {
  const { formatMessage } = useIntl();
  const workspaceId = useRouteWorkspaceId();
  const { url } = useRouteMatch();

  const { columns, isColumnsPending } = useAccountsTableColumns({
    workspaceId,
  });
  const { clientGridProps } = useClientDataGridBinding(childAccounts);

  const { selectedRecordId } = useCommentsRouteParams();
  const selectedAccount = childAccounts.find(
    (account) => account.id === selectedRecordId,
  );

  if (isColumnsPending) {
    return <Loading loadingLayout="table" />;
  }

  return (
    <div>
      {selectedRecordId && (
        <CommentsSidebar
          detailsPagePath={generatePath(routes.accounts.detail.recordView, {
            workspace_id: workspaceId,
            accountId: selectedRecordId,
          })}
          recordId={selectedRecordId}
          recordName={selectedAccount?.name}
          recordType={recordTypePath.accounts}
        />
      )}

      <DataGrid columns={columns} {...clientGridProps} />

      <AddNewRowLink
        className="tw-mt-1"
        title={formatMessage({
          defaultMessage: "Add Account",
          description:
            "Add button that is shown under the child accounts table",
        })}
        to={`${url}/new`}
      />
    </div>
  );
}
