import type { RecordTypePath } from "@themis/api/gen/models/recordTypePath";
import type { BaseDynamicValueProps } from "@themis/shared/components/fields/dynamic-value/base-dynamic-value";
import { BaseDynamicValue } from "@themis/shared/components/fields/dynamic-value/base-dynamic-value";
import type { ColDef, ColDefField } from "ag-grid-community";

import type { FieldConfigurationWithLayout } from "@/api/utils/fields/field-configuration-with-layout";
import { ColumnHeader } from "@/components/columns/column-header/column-header";
import { getDynamicFieldPath } from "@/utils/fields/get-dynamic-field-path";

export type ColumnOverrides<T> =
  | { [key in keyof T]?: Partial<ColDef<T>> }
  | { [key: string]: Partial<ColDef<T>> };

interface GetDynamicTableColumnsParams<T extends { id: number }> {
  recordType: RecordTypePath;
  layoutFields: FieldConfigurationWithLayout[];
  columnOverrides?: ColumnOverrides<T>;
  additionalColumns?: (ColDef<T> & { order?: number })[];
}

interface CellRendererProps {
  data: BaseDynamicValueProps["record"];
}

export function getDynamicTableColumns<
  T extends { id: number },
  K extends keyof T = keyof T,
>({
  recordType,
  layoutFields,
  columnOverrides,
  additionalColumns = [],
}: GetDynamicTableColumnsParams<T>): ColDef<T>[] {
  const columns = layoutFields.map(({ field, layoutField }): ColDef<T, K> => {
    const defaultWidth = 200;
    const width = layoutField.width || 200;
    const flex = width / defaultWidth;
    return {
      field: getDynamicFieldPath(field) as ColDefField<T, K>,
      colId: field.name,
      // used so that columns cannot be hidden by dragging them outside the table
      lockVisible: true,
      headerName: field.display_name,
      hide: !layoutField.visible,
      minWidth: width,
      flex,
      headerComponent: ColumnHeader,
      headerComponentParams: {
        field,
        recordType,
      },
      cellStyle: { padding: "3px 8px" },
      cellRenderer: ({ data, ...rest }: CellRendererProps) => {
        if (!data) {
          return null;
        }

        return (
          <BaseDynamicValue configuration={field} record={data} {...rest} />
        );
      },
      ...(columnOverrides?.[field.path as keyof T] || {}),
    };
  });
  const allColumns = columns.concat(additionalColumns);

  const sortedColumns = allColumns
    .map((column, idx) => ({
      ...column,
      order:
        "order" in column && typeof column.order === "number"
          ? column.order
          : idx,
    }))
    .sort((a, b) => a.order - b.order);

  return sortedColumns.map(({ order, ...column }) => column);
}
