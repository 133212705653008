import type { MediaFile } from "@themis/ui-library/components/data-display/media/types/media-file";
import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext } from "react";

export interface InteractiveMediaContextType {
  file: MediaFile;
  zoom: {
    level: number;
    setLevel: Dispatch<SetStateAction<number>>;
  };
}

export const InteractiveMediaContext =
  createContext<null | InteractiveMediaContextType>(null);

export function useInteractiveMediaContext() {
  const context = useContext(InteractiveMediaContext);
  if (!context) {
    throw new Error(
      "useInteractiveMediaContext must be used within a InteractiveMediaContext.Root component",
    );
  }
  return context;
}
