import type { ListCommentsPathParams } from "@themis/api/gen/models/commentsController/listComments";
import { ToggleButton } from "@themis/ui";
import { PiChatCircleDotsBold } from "react-icons/pi";
import { useIntl } from "react-intl";

import { useCommentsRouteParams } from "../use-comments-route-params/use-comments-route-params";

interface CommentsCellProps {
  commentsCount: number;
  recordType: ListCommentsPathParams["record_type"];
  recordId: ListCommentsPathParams["record_id"];
}

export function CommentsCell({
  commentsCount,
  recordId,
  recordType,
}: CommentsCellProps) {
  const { formatMessage } = useIntl();

  const {
    close: closeComments,
    isOpen,
    open: openComments,
    selectedRecordId,
  } = useCommentsRouteParams();

  const toggle = (pressed: boolean) => {
    if (pressed) {
      openComments(recordId);
    } else {
      closeComments();
    }
  };

  return (
    <ToggleButton
      pressed={isOpen && recordId === selectedRecordId}
      onPressedChange={toggle}
      aria-label={formatMessage(
        {
          defaultMessage: "{recordType} comments",
        },
        { recordType },
      )}
      aria-describedby={`${recordType}-id-${recordId}`}
      variant="transparent"
      className="tw-h-full tw-w-full tw-rounded-none tw-p-0 tw-text-sm tw-font-medium"
    >
      <PiChatCircleDotsBold />
      <span>{commentsCount}</span>
    </ToggleButton>
  );
}
