import type { ReactElement } from "react";

import { CircularProgress } from "../../feedback/circular-progress/circular-progress";
import type { StackProps } from "../../layout/stack/stack";
import { Stack } from "../../layout/stack/stack";

export function LoadingOverlay(props: StackProps): ReactElement {
  return (
    <Stack
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      role="status"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <CircularProgress color="primary" variant="indeterminate" />
    </Stack>
  );
}
