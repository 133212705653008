import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { matchPath, Route, Switch, useLocation } from "react-router-dom";

import { LOADING_DELAY } from "@/components/constants";
import {
  getRecordName,
  nameFromModuleWorkspace,
} from "@/components/helpers/nameForThemisModuleIdentifier";
import Loading from "@/components/Loading";
import ManageApprovalsStepsPage from "@/components/shared/ManageApprovalsPage";
import Table from "@/components/table/Table";
import { useFilters } from "@/hooks/useFilters";
import { useIsFeatureEnabled } from "@/hooks/useIsFeatureEnabled";
import { useTableData } from "@/hooks/useTableData";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

import { useMainStore } from "../../../contexts/Store";
import { useScrollToError } from "../../../hooks/useScrollToError";
import PoliciesLibraryPage from "./policies-library/policies-library.page";
import PoliciesArchivedPage from "./PoliciesArchivedPage";
import PoliciesAttestationPage from "./PoliciesAttestationPage";
import PoliciesAuditTrailPage from "./PoliciesAuditTrailPage";
import PoliciesCreativeViewPage from "./PoliciesCreativeViewPage";
import PoliciesDetailViewPage from "./PoliciesDetailViewPage";
import PoliciesDraftPage from "./PoliciesDraftPage";
import PoliciesFinalizedPage from "./PoliciesFinalizedPage";
import PoliciesHistoricalVersionsPage from "./PoliciesHistoricalVersionsPage";
import PoliciesViewBuildChecklist from "./PoliciesViewBuildChecklist";

export function isPolicyDetailViewRoute(pathName: string): boolean {
  return (
    Boolean(
      matchPath(pathName, {
        path: "/workspaces/:workspace_id/modules/policy/:record_version_id",
        exact: true,
      }),
    ) && RegExp(/\/modules\/policy\/[0-9]/).test(pathName)
  );
}

interface Props {
  testID?: string;
}

function PoliciesPage({ testID }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  useTableData();

  // Variables
  const { data } = mainStore.policies;

  const { isCurrentWorkspaceActive } = mainStore.workspaces;
  const moduleWorkspaceID = data.module_workspace_id!;
  const fields = mainStore.fields.list;
  const recordVersions = mainStore.recordVersions.list;
  const { tableName, workspaceID, isIW: isInternal } = mainStore.context;
  const { canManageSections } = mainStore.userPermissions;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const recordName = getRecordName("policy", moduleWorkspaces, true);
  const recordNamePlural = getRecordName("policy", moduleWorkspaces, false);
  const moduleName = nameFromModuleWorkspace("policy", moduleWorkspaces);
  const hideThemisPoliciesLibraryFeatureEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_HIDE_THEMIS_POLICIES_LIBRARY,
  );

  // State
  const [showAddNewSection, setShowAddNewSection] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(Boolean(!testID));

  // refs
  const timer = useRef<number>();

  // Hooks
  const location = useLocation();
  useScrollToError({ block: "center" });

  const filtersData = useFilters({
    fields,
  });

  // Effects
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = window.setTimeout(() => {
      setLoading(!!shouldShowLoading());
    }, LOADING_DELAY);
  }, [fields]);

  // Hide NewSectionTag after successful creation
  useEffect(() => {
    if (
      mainStore.sectionTags.current &&
      Object.keys(mainStore.sectionTags.current).length !== 0
    ) {
      setShowAddNewSection(false);
    }
  }, [mainStore.sectionTags.current]);

  useEffect(() => {
    setButtonDisabled(recordVersions.length === 0);
  }, [recordVersions]);

  useEffect(() => {
    if (location.pathname) {
      setShowCheckbox(false);
    }
  }, [location.pathname]);

  const addNewSection = () => {
    setShowAddNewSection((prevState) => !prevState);
  };

  function shouldShowLoading() {
    if (
      location.pathname.includes(
        `/workspaces/${workspaceID}/modules/policy/attachment_view`,
      )
    ) {
      return false;
    }
    if (
      location.pathname ===
      `/workspaces/${workspaceID}/modules/policy/attestation`
    ) {
      return false;
    }
    if (
      location.pathname === `/workspaces/${workspaceID}/modules/policy/archived`
    ) {
      return false;
    }
    if (isPolicyDetailViewRoute(location.pathname)) {
      return false;
    }
    if (fields.length === 0) {
      return true;
    }

    return false;
  }

  const showCTA =
    recordVersions.length < 3 &&
    location.pathname === `/workspaces/${workspaceID}/modules/policy/drafts`;

  if (loading) {
    return (
      <div className="dashboard-content">
        <div className="dashboard-content-wrap">
          <Table>
            <Loading loadingLayout="table" showTableHeader />
          </Table>
        </div>
      </div>
    );
  }

  return (
    <Switch>
      <Route exact path="/workspaces/:workspace_id/modules/policy/archived">
        <PoliciesArchivedPage />
      </Route>

      <Route
        exact
        path="/workspaces/:workspace_id/modules/policy/:record_version_id/historical-versions"
      >
        <PoliciesHistoricalVersionsPage />
      </Route>

      <Route
        exact
        path="/workspaces/:workspace_id/modules/:moduleIdentifier/audit_trail"
      >
        <PoliciesAuditTrailPage
          isRecordView={false}
          title="Module Level Audit Trail"
        />
      </Route>
      <Route
        exact
        path="/workspaces/:workspace_id/modules/:moduleIdentifier/:record_version_id/audit_trail"
      >
        <PoliciesAuditTrailPage isRecordView title="Record Level Audit Trail" />
      </Route>

      <Route exact path="/workspaces/:workspace_id/modules/policy/finalized">
        <PoliciesFinalizedPage
          recordNamePlural={recordNamePlural}
          // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
          tableName={tableName}
          setShowCheckbox={setShowCheckbox}
          buttonDisabled={buttonDisabled}
          moduleWorkspaceID={moduleWorkspaceID}
          showCTA={showCTA}
          isInternal={isInternal}
          showCheckbox={showCheckbox}
          recordVersionsLength={recordVersions.length}
          filtersData={filtersData}
          hasModuleWriteAccess={hasModuleWriteAccess}
        />
      </Route>

      <Route path="/workspaces/:workspace_id/modules/policy/attestation">
        <PoliciesAttestationPage
          // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
          tableName={tableName}
          recordName={moduleName}
          recordNamePlural={recordNamePlural}
          setShowCheckbox={setShowCheckbox}
          buttonDisabled={buttonDisabled}
          moduleWorkspaceID={moduleWorkspaceID}
          showCTA={showCTA}
          isInternal={isInternal}
          showCheckbox={showCheckbox}
        />
      </Route>

      {!hideThemisPoliciesLibraryFeatureEnabled && (
        <Route path="/workspaces/:workspace_id/modules/policy/policies_library">
          <PoliciesLibraryPage />
        </Route>
      )}

      <Route
        key="/modules/policy/attachment_view"
        path="/workspaces/:workspace_id/modules/policy/attachment_view/:record_version_id/:file_id"
      >
        <PoliciesCreativeViewPage />
      </Route>

      <Route path="/workspaces/:workspace_id/modules/policy/drafts">
        <PoliciesDraftPage
          // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
          tableName={tableName}
          setShowCheckbox={setShowCheckbox}
          buttonDisabled={buttonDisabled}
          addNewSection={addNewSection}
          showCTA={showCTA}
          isInternal={isInternal}
          showCheckbox={showCheckbox}
          showAddNewSection={showAddNewSection}
          moduleWorkspaceID={moduleWorkspaceID}
          canManageSections={canManageSections}
          filtersData={filtersData}
          moduleName={moduleName}
          recordVersions={recordVersions}
          onCancelAddNewSection={() => setShowAddNewSection(false)}
        />
      </Route>

      {isCurrentWorkspaceActive && (
        <Route
          exact
          path="/workspaces/:workspace_id/modules/policy/manage-approvals-steps"
        >
          <ManageApprovalsStepsPage moduleIdentifier="policy" />
        </Route>
      )}

      <Route
        exact
        path="/workspaces/:workspace_id/modules/policy/:record_version_id"
      >
        <PoliciesDetailViewPage recordName={recordName} />
      </Route>

      <Route path="/workspaces/:workspace_id/modules/:module_identifier/custom-checklists/:record_id/create/:field_name">
        <PoliciesViewBuildChecklist />
      </Route>

      <Route
        exact
        path="/workspaces/:workspace_id/modules/policy/document_library_preview/:file_id/"
      >
        <PoliciesCreativeViewPage title="View Policy Template" />
      </Route>

      <Route path="/workspaces/:workspace_id/modules/:module_identifier/custom-checklists/:checklist_id/update">
        <PoliciesViewBuildChecklist editingMode />
      </Route>
      <Route path="/workspaces/:workspace_id/modules/:module_identifier/custom-checklists-templates/:checklist_id/update">
        <PoliciesViewBuildChecklist editingMode isTemplate />
      </Route>
    </Switch>
  );
}

export default observer(PoliciesPage);
